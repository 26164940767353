<template>
  <div class="performance-management">
    <h1>{{ title }}</h1>
    <button @click="handleAddPerformance" :disabled="isSaving">添加性能</button>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>性能图片</th>
            <th>性能文本</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(performance, index) in combinedPerformanceList" :key="performance.PerformanceID || index">
            <td>{{ index + 1 }}</td>
            <td>
              <input type="file" @change="handleFileUpload($event, index)" />
              <img :src="performance.PerformanceImage" alt="性能图片" class="performance-image" v-if="performance.PerformanceImage" />
            </td>
            <td>
              <textarea v-model="performance.PerformanceText" rows="4" cols="50"></textarea>
            </td>
            <td>
              <button class="delete-btn" @click="handleRemovePerformance(index)" :disabled="isSaving">删除</button>
              <button class="save-btn" @click="handleSave(index)" :disabled="isSaving">
                {{ isSaving ? '保存中...' : '保存' }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import '@/assets/table.css';
import { defineProps } from 'vue';

const props = defineProps({
  configId: {
    type: Number,
    required: true,
  },
});

const store = useStore();
const localPerformanceList = computed(() => store.getters['performance/performanceList']);
const temporaryPerformanceList = ref([]); // 临时存储尚未保存的性能条目
const title = ref('性能介绍');
const isSaving = ref(false); // 添加加载状态

const combinedPerformanceList = computed(() => [
  ...localPerformanceList.value,
  ...temporaryPerformanceList.value,
]);

const fetchPerformanceList = () => {
  store.dispatch('performance/fetchPerformanceList', props.configId);
};

const handleAddPerformance = () => {
  if (!isSaving.value) {
    temporaryPerformanceList.value.push({ ConfigID: props.configId, PerformanceImage: '', PerformanceText: '' });
  }
};

const handleFileUpload = (event, index) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      combinedPerformanceList.value[index].PerformanceImage = e.target.result;
      combinedPerformanceList.value[index].PerformanceImageFile = file;
    };
    reader.readAsDataURL(file);
  }
};

const handleRemovePerformance = (index) => {
  if (index < localPerformanceList.value.length) {
    const performance = localPerformanceList.value[index];
    store.dispatch('performance/deletePerformance', performance.PerformanceID);
  } else {
    temporaryPerformanceList.value.splice(index - localPerformanceList.value.length, 1);
  }
};

const handleSave = async (index) => {
  if (isSaving.value) return; // 防止重复点击

  isSaving.value = true;
  const performance = combinedPerformanceList.value[index];
  const formData = new FormData();
  formData.append('config_id', props.configId);
  formData.append('performance_text', performance.PerformanceText);
  if (performance.PerformanceImageFile) {
    formData.append('performance_image', performance.PerformanceImageFile);
  }

  try {
    if (performance.PerformanceID) {
      console.log('保存',performance.PerformanceID);
      // 如果 PerformanceID 存在，说明是更新操作
      await store.dispatch('performance/addPerformance', { formData, performance_id: performance.PerformanceID });
    } else {
      // PerformanceID 不存在，说明是新增操作
      console.log('新增');
      await store.dispatch('performance/addPerformance', { formData });
      // 成功后将临时条目从 temporaryPerformanceList 移除
      temporaryPerformanceList.value.splice(index - localPerformanceList.value.length, 1);
    }

    // 保存或更新后，重新获取性能列表
    await fetchPerformanceList();

  } catch (error) {
    console.error('保存失败', error);
  } finally {
    isSaving.value = false;
  }
};

onMounted(() => {
  fetchPerformanceList();
});

watch(() => props.configId, fetchPerformanceList);
</script>

<style scoped>
.performance-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.input-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.performance-image {
  width: 100px;
  height: auto;
  display: block;
}
</style>
