// src/store/configuration.js

import { getConfigurationByID } from '@/api/api';
import { notifyError } from '@/utils/notify';

const configurationModule = {
    state: () => ({
        configuration: null,
    }),
    mutations: {
        SET_CONFIGURATION(state, configuration) {
            state.configuration = configuration;
        },
    },
    actions: {
        async fetchConfigurationByID({ commit }, id) {
            try {
                const response = await getConfigurationByID(id);
                commit('SET_CONFIGURATION', response.data);
                return null; // 无错误时返回 null
            } catch (error) {
                let errorMessage = '获取配置详情时发生错误';
                if (error.response && error.response.status === 404) {
                    errorMessage = '未找到指定配置';
                }
                notifyError(errorMessage);
                return errorMessage; // 返回错误信息
            }
        },
    },
    getters: {
        configuration: state => state.configuration,
    },
};

export default configurationModule;
