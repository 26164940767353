<template>
	<div class="header">
		<!-- logo-图标 (编号: 100010001) -->
		<img :src="logoUrl" alt="Logo" class="logo" @click="goToMainPage" />
		<!-- 侧拉导航栏按钮 (编号: 100010002) -->
		<button class="side-nav-button" @click="toggleSideNav">{{ $t('message.toggleNav') }}</button>
	</div>
</template>

<script>
	import { useRouter, useRoute } from "vue-router";
	import { ref, onMounted } from "vue";
	import axios from "axios";


	export default {
		name: "HeaderComponent",
		setup() {
			const router = useRouter();
			const logoUrl = ref("");
			const route = useRoute();  

			const goToMainPage = () => {
				router.push({ name: "MainPage" });
			};
			// 动态获取 logo，根据路径判断
			const fetchLogo = async () => {
				if (route.path.includes('/user')) {
					// 如果路径包含 /user，使用远程获取的 logo
					try {
						const response = await axios.get("https://www.etong-ev.com/api/yitong/company/logo");
						logoUrl.value = response.data.logo;
						localStorage.setItem("companyLogo", response.data.logo);
					} catch (error) {
						console.error("Failed to fetch company logo:", error);
					}
				} else if (route.path.includes('/hongya')) {
					// 如果路径包含 /hongya，使用静态资源中的 logo
					logoUrl.value = require('@/assets/hongyalogo.png');  // 静态logo路径
				}
			};

			// 监听页面加载时的路径
			onMounted(() => {
				fetchLogo();
			});

			return {
				goToMainPage,
				logoUrl,
			};
		},
		methods: {
			toggleSideNav() {
				this.$emit("toggle-side-nav");
			},
		},
	};
</script>

<style scoped>
	.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #000000;
    padding: 1vh 2vw; /* 使用 vh 和 vw 单位确保高度和宽度自适应 */
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: auto; /* 自适应高度 */
    max-height: 80px; /* 最大高度限制为 80px */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
    width: auto;
    height: 6vh; /* 使用 vh 单位让图标高度自适应 */
    max-height: 60px; /* 限制最大高度为 60px */
    cursor: pointer;
    transition: transform 0.3s;
}

.logo:hover {
    transform: scale(1.05);
}

.side-nav-button {
	width: 30vw;
    max-width: 150px;
    height: 5vh; /* 使用 vh 单位自适应按钮高度 */
    max-height: 40px; /* 限制最大高度为 40px */
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
	font-size: 14px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.side-nav-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


</style>
