<template>
  <div class="detail-box">
    <!-- 图片显示 -->
    <div class="image-container">
      <img :src="OtherImage" alt="Image" class="image" />
      <!-- 显示附加内容，排除 title 为 "表现" 的部分 -->
      <div v-if="AdditionalContent" class="additional-content">
        <template v-for="(section, sectionIndex) in AdditionalContent" :key="sectionIndex">
          <div v-if="parseTitle(section.title, locale) !== '表现' && parseTitle(section.title, locale) !== 'Performance'">
            <h3>{{ parseTitle(section.title, locale) }}</h3>
            <ul>
              <li v-for="(param, paramIndex) in section.parameters" :key="paramIndex">
                {{ parseTitle(param.title, locale) }}: {{ parseTitle(param.data, locale) }}
              </li>
            </ul>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import { parseTitle } from '@/utils/i18nHelper';

const { locale } = useI18n();  // 获取当前语言环境

// 接收从父组件传递来的 props
defineProps({
  OtherImage: String,
  OtherText: String,
  AdditionalContent: Array
});
</script>

<style scoped>
/* 图片容器，确保文字在图片上面自适应显示 */
.image-container {
  position: relative;
  width: 100%;
  max-width: 500px;
}

/* 图片样式，确保高度自适应 */
.image {
  width: 100%;
  height: auto;
  display: block;
}

/* 在图片上显示附加内容 */
.additional-content {
  position: absolute;
  top: 20%; /* 控制显示位置 */
  left: 2%;
  width: 55%; /* 限制宽度 */
  padding: 10px;
  color: white;
  word-wrap: break-word;
  white-space: pre-wrap; /* 保留换行 */
  max-height: 70%; /* 设置最大高度为图片的百分比，确保内容不会溢出 */
  overflow-y: auto; /* 启用垂直滚动 */
}


h3 {
  font-size: 14px; /* 将字体大小减小为14px */
  font-weight: bold;
  margin-bottom: 5px; /* 减小标题和内容之间的间距 */
}

ul {
  padding-left: 10px; /* 减小左侧缩进 */
  margin: 0; /* 移除多余的 margin */
}

li {
  font-size: 12px; /* 将字体大小减小为12px */
  line-height: 1.5; /* 缩小行距，使内容更紧凑 */
  margin-bottom: 3px; /* 缩小每个项目之间的间距 */
}
</style>
