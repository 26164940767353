<template> 
    <div class="page-container">
    <ProductMainBox />
    
      <!-- 600020000 - Section 1 -->
      <div class="content-box">
        <!-- 600020001 - Title -->
        <div class="title">{{ title1 }}</div>
        <!-- 600020002 - Content Text -->
        <div class="content">{{ content1 }}</div>
      </div>
  
      <!-- 600030000 - Section 2 -->
      <div class="content-box">
        <!-- 600030001 - Title -->
        <div class="title">{{ title2 }}</div>
        <!-- 600030002 - Content Text -->
        <div class="content">{{ content2 }}</div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import ProductMainBox from '../components/ProductMainBox.vue';

  // 600020001 - Title for section 1
  const title1 = ref('标题 1');
  // 600020002 - Content text for section 1
  const content1 = ref('这是第一部分的具体文字内容。');
  
  // 600030001 - Title for section 2
  const title2 = ref('标题 2');
  // 600030002 - Content text for section 2
  const content2 = ref('这是第二部分的具体文字内容。');
  </script>
  
  <style scoped>
  /* 600000000 - Main container styling */
  .page-container {
    width: 100%;
  }
  
  /* 600020000, 600030000 - Content box styling */
  .content-box {
    margin-bottom: 30px;
    margin-left: 10%;
  }
  
  /* 600020001, 600030001 - Title styling */
  .title {
    /* Adjusted from 'left: 29px; top: 694px;' */
    margin-bottom: 10px;
    color: rgba(22, 22, 25, 1);
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    font-family: 'PingFangSC-bold', sans-serif;
  }
  
  /* 600020002, 600030002 - Content text styling */
  .content {
    /* Adjusted from 'left: 29px; top: 722px;' */
    color: rgba(22, 22, 25, 1);
    font-size: 12px;
    line-height: 17px;
    font-family: 'PingFangSC-regular', sans-serif;
  }
  </style>
  