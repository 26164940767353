<template>
  <!-- 产品展示 -->
  <div class="product-display">
    <h1 class="title">{{ parseTitle(productTitle, locale) }}</h1>
    <h2 class="subtitle">{{ parseTitle(productSubtitle, locale) }}</h2>

    <!-- 使用 img 标签直接显示图片 -->
    <img :src="ProductImage" alt="Car Background" class="product-image" />
  </div>

  <!-- 参数介绍 -->
  <div v-if="parameterSections && parameterSections.length > 0">
    <div v-for="(section, secIndex) in parameterSections" :key="secIndex" class="parameter-intro">
      <h1 class="main-title">{{ parseTitle(section.title, locale) }}</h1>
      <div class="parameter-list">
        <div v-for="(item, index) in section.parameters" :key="index" class="parameter-item">
          <span class="parameter-title">{{ parseTitle(item.title, locale) }}</span>
          <span class="parameter-data">{{ parseTitle(item.data, locale) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="no-parameters">
    <small style="color: black;">{{ $t('message.noParameters') }}</small>
  </div>

  <!-- 底部盒子 -->
  <FooterComponent />
  <div style="height: 150px;"></div>
</template>

<script setup>
import { useStore } from 'vuex';
import FooterComponent from '../components/FooterComponent.vue';
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import { parseTitle } from '@/utils/i18nHelper'; 
import { useI18n } from 'vue-i18n';  // 使用 vue-i18n 处理多语言

const { locale } = useI18n();
const route = useRoute();

// 获取 Vuex store 实例
const store = useStore();

// 产品标题数据
const productTitle = computed(() => store.getters.productTitle);
const productSubtitle = computed(() => store.getters.productSubtitle);
const ProductImage = route.params.ProductImage;

// 确保 parameterSections 为一个数组，即使后端返回 null
const parameterSections = computed(() => store.getters['parameters/parameterSections'] || []);


const configID = route.params.configID;

onMounted(() => {
  store.dispatch('parameters/fetchParameters', configID);
  store.dispatch('fetchProductDetails', configID); // 调用 action 获取数据
});

</script>

<style scoped>
/* 样式部分保持不变，或根据需要调整 */
.product-display {
  position: relative;
  top: 59px;
  width: 100%;
  height: 331px;
  background-color: rgba(255, 255, 255, 1);
}

.title {
  position: relative;
  left: 27px;
  top: 35px;
  width: auto;
  height: 29.9px;
  line-height: 28px;
  color: rgba(22, 22, 25, 1);
  font-size: 20px;
  text-align: left;
  font-family: PingFangSC-regular;
}

.subtitle {
  position: relative;
  left: 27px;
  top: 30px;
  width: auto;
  height: 24.56px;
  line-height: 23px;
  color: rgba(22, 22, 25, 1);
  font-size: 14px;
  text-align: left;
}

.product-image {
  position: relative;
  left: 109px;
  top: 50px;
  width: 162px;
  height: 161px;
  object-fit: cover; /* 确保图片按比例填充容器 */
  border-radius: 8px; /* 可选，添加圆角效果 */
}

.parameter-intro {
  position: relative;
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 30px;
}

.main-title {
  margin-bottom: 20px;
  line-height: 23px;
  color: rgba(22, 22, 25, 1);
  font-size: 16px;
  text-align: left;
  font-family: PingFangSC-bold;
}

.parameter-list {
  display: flex;
  flex-direction: column;
}

.parameter-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.parameter-title, .parameter-data {
  width: 50%;
  text-align: left;
  color: rgba(22, 22, 25, 1);
  font-size: 14px;
  font-family: PingFangSC-regular;
}

.no-parameters {
  text-align: center;
  padding: 20px;
  color: rgba(22, 22, 25, 0.6);
}
</style>
