<template>
  <div class="category-management">
    <h1>{{ title }}</h1>
    <div class="input-group">
      <input v-model="newCategory" placeholder="输入参数分类名称" />
      <button @click="handleAddCategory">新增参数分类</button>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>参数分类名称</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category, index) in categoryList" :key="category.CategoryID">
            <td>{{ index + 1 }}</td>
            <td>
              <input v-model="category.CategoryName" />
            </td>
            <td>
              <button class="save-btn" @click="handleUpdateCategory(category.CategoryID, category)">保存</button>
              <button class="delete-btn" @click="handleDeleteCategory(category.CategoryID)">删除</button>
              <button class="expand-btn" @click="handleExpandParameters(category.CategoryID)">展开</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="expandedCategory">
        <h2>参数标题 - {{ expandedCategory.CategoryName }}</h2>
        <div class="input-group">
          <input v-model="newParameterTitle" placeholder="输入参数标题名称" />
          <button @click="handleAddParameterTitle(expandedCategory.CategoryID)">新增参数标题</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>序号</th>
              <th>参数标题名称</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(parameter, index) in parameterTitleList" :key="parameter.ParameterID">
              <td>{{ index + 1 }}</td>
              <td>
                <input v-model="parameter.ParameterName" />
              </td>
              <td>
                <button class="save-btn" @click="handleUpdateParameterTitle(parameter.ParameterID, parameter)">保存</button>
                <button class="delete-btn" @click="handleDeleteParameterTitle(parameter.ParameterID)">删除</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import '@/assets/table.css';

const store = useStore();
const categoryList = computed(() => store.getters.categoryList);
const parameterTitleList = computed(() => store.getters.parameterTitleList);
const newCategory = ref('');
const newParameterTitle = ref('');
const title = ref('参数分类管理');
const expandedCategory = ref(null);

const fetchCategoryList = () => {
  store.dispatch('fetchCategoryList');
};

const handleAddCategory = () => {
  if (newCategory.value.trim() !== '') {
    store.dispatch('addCategory', { CategoryName: newCategory.value });
    newCategory.value = '';
  }
};

const handleUpdateCategory = (id, category) => {
  store.dispatch('updateCategory', { id, category });
};

const handleDeleteCategory = (id) => {
  store.dispatch('deleteCategory', id);
};

const handleExpandParameters = (categoryID) => {
  const category = categoryList.value.find(cat => cat.CategoryID === categoryID);
  expandedCategory.value = category;
  store.dispatch('fetchParameterTitlesByCategory', categoryID);
};

const handleAddParameterTitle = (categoryID) => {
  if (newParameterTitle.value.trim() !== '') {
    store.dispatch('addParameterTitle', { CategoryID: categoryID, ParameterName: newParameterTitle.value });
    newParameterTitle.value = '';
  }
};

const handleUpdateParameterTitle = (id, parameter) => {
  const parameterData = {
    ParameterID: parameter.ParameterID,
    CategoryID: parameter.CategoryID, // 确保包含 CategoryID
    ParameterName: parameter.ParameterName,
  };
  store.dispatch('updateParameterTitle', { id, parameterTitle: parameterData });
};
const handleDeleteParameterTitle = (id) => {
  store.dispatch('deleteParameterTitle', id);
};

onMounted(() => {
  fetchCategoryList();
});
</script>

<style scoped>
.category-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.input-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
}

button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}
</style>
