<template>
  <div class="car-box" @click="navigateToDetailsPage(car.ConfigID)">
    <!-- 编号: 100030000 车盒子内容 -->
    <img 
      :src="car.low_res_image_url" 
      :data-src="car.HomepageImage" 
      alt="Car Image" 
      class="car-image" 
      ref="carImage"
      @load="onLowResImageLoad"
    />
    <div class="car-details">
      <h3 class="car-model">{{ car.product_model }}</h3>
      <p class="car-description">{{ car.HomepageDescription }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const props = defineProps({
  car: {
    type: Object,
    required: true,
  },
  isFirstImage: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['first-image-loaded']);
const router = useRouter();
const store = useStore();
const carImage = ref(null);

// 在低分辨率图片加载完成后加载高清图
const onLowResImageLoad = () => {
  if (carImage.value) {
    carImage.value.src = carImage.value.dataset.src;
    if (props.isFirstImage) {
      emit('first-image-loaded');
    }
  }
};

// 在跳转之前预加载页面数据和图片
const navigateToDetailsPage = async (configID) => {
  // 预加载下一页面的数据
  await store.dispatch('fetchConfigurationByID', configID);
  await store.dispatch('fetchTextImageList', configID);

  // 预加载图片
  const configuration = store.getters.configuration;
  const detailImages = [
    configuration.DetailImage,
    configuration.ProductImage,
    ...store.getters.textImageList.map((detail) => detail.OtherImage),
  ];

  // 使用 JavaScript Image 对象预加载图片
  detailImages.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  // 跳转到下一页面
  router.push({ name: 'CarDetailsPage', params: { configID } });
};
</script>

<style scoped>
.car-box {
  position: relative;
  height: 50vh; /* 保持高度与设计一致 */
  aspect-ratio: 2.2 / 3; 
  line-height: 20px;
  border-radius: 20px; /* 保持20px的圆角 */
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 23px; /* 调整为适应视口高度的字体大小 */
  text-align: center;
  font-family: Roboto;
  margin-right: 2.3vw; /* 根据10px间距转换为2.3vw */
  margin-left: 2vw; /* 根据10px间距转换为2.3vw */
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer; /* 鼠标悬停时显示手型，表示可点击 */
}


.car-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4.65vw; /* 保持20px的圆角 */
}

.car-details {
  position: absolute;
  bottom: 4.3vh; /* 距离底部20px转换为4.3vh */
  left: 50%;
  transform: translateX(-50%);
  width: 70vw; /* 基于原始设计的宽度 */
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景提升可读性 */
  border-radius: 2.3vw; /* 保持10px的圆角 */
  padding: 1.5vh; /* 保持10px的内边距 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* 转换阴影为视口单位 */
}

.car-model {
  margin: 0;
  color: #fff;
  font-size: 20px; /* 调整为视口高度的2.5% */
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* 增加文本阴影，提升可读性 */
}

.car-description {
  margin: 1vh 0 0; /* 转换顶部间距为1vh */
  color: #ddd;
  font-size: 12px; /* 调整为视口高度的2% */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* 增加文本阴影，提升可读性 */
}

</style>
