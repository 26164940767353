<template>
  <div>
    <div style="height: 10vh;"></div>
    <div class="car-info-box">
      <!-- 编号: 300010001 车辆图片 -->
      <div class="car-image">
        <img :src="store.getters['models/selectedModel']?.product_image || ''" alt="车辆图片" v-if="store.getters['models/selectedModel']">
      </div>
      <!-- 编号: 300010002 车辆名字 -->
      <div class="car-name">{{ store.getters['models/selectedModel']?.product_model || '车辆名字' }}</div>
      <div class="model-price-container">
        <div class="data-title">{{ $t('message.model') }}</div>
        <!-- 编号: 300010003 型号选择框 -->
        <select class="model-select-box" v-model="selectedModelId" @change="handleModelChange">
          <option value="">{{ $t('message.selectModel') }}</option>
          <option v-for="model in models" :key="model.config_id" :value="model.config_id">
            {{ model.configuration }}
          </option>
        </select>
        <!-- 编号: 300010004 数据部分 -->
        <div class="data-section">
          <div class="data-title">{{ $t('message.priceRange') }}</div>
          <div class="price-range" v-if="store.getters['models/selectedModel'] && store.getters['models/selectedModel'].min_price && store.getters['models/selectedModel'].max_price">
            {{ store.getters['models/selectedModel'].min_price }} - {{ store.getters['models/selectedModel'].max_price }} 
          </div>
          <div class="no-price" v-else>{{ $t('message.noPrice') }}</div>
        </div>
      </div>
    </div>

    <div class="contact-form">
      <!-- 编号: 300020001 联系信息 -->
      <div class="contact-info">{{ $t('message.contactInfo') }}</div>

      <!-- 编号: 300020002 国家-标题 -->
      <div class="country-title">{{ $t('message.country') }}</div>
      <!-- 编号: 300020100 国家输入框 -->
      <input v-model="country" class="input-box country-dropdown" type="text" :placeholder="$t('message.country')">

      <!-- 编号: 300020101 城市-标题 -->
      <div class="city-title">{{ $t('message.city') }}</div>
      <!-- 编号: 300020102 城市输入框 -->
      <input v-model="city" class="input-box" type="text" :placeholder="$t('message.city')">

      <!-- 编号: 300020003 邮箱-标题 -->
      <div class="email-title">{{ $t('message.email') }}</div>
      <!-- 编号: 300020004 邮箱输入框 -->
      <input v-model="email" class="input-box email-input" type="email" :placeholder="$t('message.email')">

      <!-- 编号: 300020005 手机号码-标题 -->
      <div class="phone-title">{{ $t('message.phone') }}</div>
      <!-- 编号: 300020006 手机号码输入框 -->
      <input v-model="phone" class="input-box phone-input" type="tel" :placeholder="$t('message.phone')">

      <!-- 编号: 300020007 留言-标题 -->
      <div class="message-title">{{ $t('message.message') }}</div>
      <!-- 编号: 300020008 留言输入框 -->
      <textarea v-model="message" class="input-box message-input" :placeholder="$t('message.message')"></textarea>

      <!-- 编号: 300020009 提交按钮 -->
      <button class="get-price-button" @click="handleSubmit">{{ $t('message.submit') }}</button>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import axios from 'axios';
import geoMixin from '../mixins/geoMixin.js';

const route = useRoute();
const configID = route.params.configID;
console.log(configID)

const store = useStore();
const models = ref([]);
const selectedModelId = ref('');

const country = ref('');
const city = ref('');
const email = ref('');
const phone = ref('');
const message = ref('');

// 获取models数据的函数
const fetchModels = async () => {
  await store.dispatch('models/fetchModels', configID);
  models.value = store.getters['models/models'];
};

// 监听 models 数据变化，当 models 有数据时自动选中第一个型号
watch(() => models.value, (newModels) => {
  if (newModels.length > 0 && !selectedModelId.value) {
    selectedModelId.value = newModels[0].config_id;  // 自动选中第一个型号
    handleModelChange();
  }
});

const handleModelChange = () => {
  store.dispatch('models/selectModel', selectedModelId.value);
};

const handleSubmit = async () => {
  try {
    const ipResponse = await axios.get('https://api64.ipify.org?format=json');
    const ip = ipResponse.data.ip;
    const userId = geoMixin.methods.getCachedUserId(ip);
    if (userId) {
      await store.dispatch('updateUserContact', {
        UserID: userId,
        Name: '',
        Email: email.value,
        Phone: phone.value,
        Country: country.value,
        City: city.value,
        MessageContent: message.value,
        AccessTime: new Date().toISOString(),
        FollowUp: false
      });
    } else {
      console.error('未能找到缓存的 UserID');
    }
  } catch (error) {
    console.error('获取 IP 或提交数据失败:', error);
  }
};

// 获取地理位置信息
geoMixin.methods.getIpGeolocation().then((geoData) => {
  if (geoData) {
    country.value = geoData.country || '';
    city.value = geoData.city || '';
  } else {
    console.error('未能获取到地理位置信息');
  }
});

// 在组件挂载时先获取数据
onMounted(() => {
  fetchModels();
});
</script>

<style scoped>
.car-info-box {
  font-family: 'Arial', sans-serif;
  border-radius: 8px;
  padding: 12px;
  max-width: 320px;
  margin: 15px auto;
  color: #444;
}

.car-image {
  text-align: center;
  margin-bottom: 12px;
}

.car-image img {
  width: 100%;
  height: auto;
  max-width: 200px;
  max-height: 160px;
  object-fit: cover;
}

.car-name {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 8px 0;
  color: #222;
  letter-spacing: 0.5px;
}

.model-price-container {
  margin-top: 12px;
}

.model-select-box {
  width: 100%;
  padding: 6px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #bbb;
  background-color: #fff;
  color: #333;
  appearance: none;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.data-section {
  margin-top: 15px;
  padding-top: 8px;
  border-top: 1px solid #ddd;
}

.data-title {
  font-size: 14px;
  font-weight: 600;
  color: #666;
  margin-bottom: 6px;
}

.price-range {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.no-price {
  font-size: 14px;
  color: #999;
  text-align: center;
}

/* 编号: 300020000 表单 */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* 编号: 300020001 联系信息 */
.contact-info {
  margin-bottom: 15px;
  color: rgba(16,16,16,1);
  font-size: 18px;
  font-family: PingFangSC-regular;
  text-align: left;
  width: 100%;
}

/* 编号: 300020002 国家-标题 */
.country-title,
.city-title,
.email-title,
.phone-title,
.message-title {
  margin-bottom: 5px;
  color: rgba(16,16,16,1);
  font-size: 16px;
  font-family: PingFangSC-regular;
  text-align: left;
  width: 100%;
}

.input-box,
.country-dropdown {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: rgba(234,233,233,1);
  border: 1px solid #ccc;
  padding: 5px;
  box-sizing: border-box;
  color: #333;
}

.country-dropdown {
  font-family: Microsoft Yahei;
}

.message-input {
  height: 80px;
}

.get-price-button {
  width: 100%;
  height: 40px;
  background-color: rgba(83,83,83,1);
  color: rgba(255,255,255,1);
  font-size: 14px;
  font-family: Roboto;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10vh;
}

.get-price-button:hover {
  background-color: rgba(70,70,70,1);
}
</style>
