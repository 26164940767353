<template>
  <div class="company-info-management">
    <h1>{{ title }}</h1>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="companyIntro">公司简介</label>
        <textarea id="companyIntro" v-model="companyInfo.CompanyIntro" rows="5"></textarea>
      </div>

      <div class="form-group">
        <label for="logoUpload">公司 Logo</label>
        <input type="file" id="logoUpload" @change="handleLogoUpload" />
        <img v-if="companyInfo.LogoPreview" :src="companyInfo.LogoPreview" alt="Company Logo" class="preview-image" />
        <p class="image-tip">提示: 请上传宽度不超过 144px，高度不超过 60px 的透明底图片，支持格式为 JPG, PNG。</p>
      </div>

      <!-- 分隔符 -->
      <div class="divider"></div>

      <!-- 微信账号 1 和邮箱 1 -->
      <div class="form-group row-layout">
        <div class="column-layout">
          <label for="wechatID1">微信联系方式 1</label>
          <input type="text" id="wechatID1" v-model="companyInfo.WechatID1" class="wechat-input" />
        </div>
        <div class="column-layout">
          <label for="wechatQRCodeUpload1">微信二维码 1</label>
          <input type="file" id="wechatQRCodeUpload1" @change="handleWechatQRCodeUpload1" />
          <img v-if="companyInfo.WechatQRCodePreview1" :src="companyInfo.WechatQRCodePreview1" alt="WeChat QR Code 1" class="preview-image" />
        </div>
        <div class="column-layout">
          <label for="contactEmail1">联系邮箱 1</label>
          <input type="email" id="contactEmail1" v-model="companyInfo.ContactEmail" />
        </div>
      </div>

      <!-- 分隔符 -->
      <div class="divider"></div>

      <!-- 微信账号 2 和邮箱 2 -->
      <div class="form-group row-layout">
        <div class="column-layout">
          <label for="wechatID2">微信联系方式 2</label>
          <input type="text" id="wechatID2" v-model="companyInfo.WechatID2" class="wechat-input" />
        </div>
        <div class="column-layout">
          <label for="wechatQRCodeUpload2">微信二维码 2</label>
          <input type="file" id="wechatQRCodeUpload2" @change="handleWechatQRCodeUpload2" />
          <img v-if="companyInfo.WechatQRCodePreview2" :src="companyInfo.WechatQRCodePreview2" alt="WeChat QR Code 2" class="preview-image" />
        </div>
        <div class="column-layout">
          <label for="contactEmail2">联系邮箱 2</label>
          <input type="email" id="contactEmail2" v-model="companyInfo.ContactEmail2" />
        </div>
      </div>

      <!-- 分隔符 -->
      <div class="divider"></div>

      <!-- 微信账号 3 和邮箱 3 -->
      <div class="form-group row-layout">
        <div class="column-layout">
          <label for="wechatID3">微信联系方式 3</label>
          <input type="text" id="wechatID3" v-model="companyInfo.WechatID3" class="wechat-input" />
        </div>
        <div class="column-layout">
          <label for="wechatQRCodeUpload3">微信二维码 3</label>
          <input type="file" id="wechatQRCodeUpload3" @change="handleWechatQRCodeUpload3" />
          <img v-if="companyInfo.WechatQRCodePreview3" :src="companyInfo.WechatQRCodePreview3" alt="WeChat QR Code 3" class="preview-image" />
        </div>
        <div class="column-layout">
          <label for="contactEmail3">联系邮箱 3</label>
          <input type="email" id="contactEmail3" v-model="companyInfo.ContactEmail3" />
        </div>
      </div>

      <!-- 分隔符 -->
      <div class="divider"></div>

      <!-- 微信账号 4 和邮箱 4 -->
      <div class="form-group row-layout">
        <div class="column-layout">
          <label for="wechatID4">微信联系方式 4</label>
          <input type="text" id="wechatID4" v-model="companyInfo.WechatID4" class="wechat-input" />
        </div>
        <div class="column-layout">
          <label for="wechatQRCodeUpload4">微信二维码 4</label>
          <input type="file" id="wechatQRCodeUpload4" @change="handleWechatQRCodeUpload4" />
          <img v-if="companyInfo.WechatQRCodePreview4" :src="companyInfo.WechatQRCodePreview4" alt="WeChat QR Code 4" class="preview-image" />
        </div>
        <div class="column-layout">
          <label for="contactEmail4">联系邮箱 4</label>
          <input type="email" id="contactEmail4" v-model="companyInfo.ContactEmail4" />
        </div>
      </div>

      <!-- 分隔符 -->
      <div class="divider"></div>

      <div class="form-group">
        <label for="whatsappNumber">WhatsApp 联系方式</label>
        <input type="text" id="whatsappNumber" v-model="companyInfo.WhatsappNumber" />
      </div>

      <div class="form-group">
        <label for="landlineNumber">座机号码</label>
        <input type="text" id="landlineNumber" v-model="companyInfo.LandlineNumber" />
      </div>

      <button type="submit">保存</button>
    </form>
  </div>
</template>



<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const title = ref('公司简介管理');
const companyInfo = ref({
  CompanyIntro: '',
  Logo: '',
  LogoPreview: '', // 本地预览
  WechatID1: '',
  WechatQRCode1: '',
  WechatQRCodePreview1: '', // 本地预览
  WechatID2: '',
  WechatQRCode2: '',
  WechatQRCodePreview2: '', // 本地预览
  WechatID3: '',
  WechatQRCode3: '',
  WechatQRCodePreview3: '', // 本地预览
  WechatID4: '',
  WechatQRCode4: '',
  WechatQRCodePreview4: '', // 本地预览
  WhatsappNumber: '',
  ContactEmail: '',
  ContactEmail2: '',
  ContactEmail3: '',
  ContactEmail4: '',
  LandlineNumber: ''
});

let logoFile = null;
let wechatQRCodeFile1 = null;
let wechatQRCodeFile2 = null;
let wechatQRCodeFile3 = null;
let wechatQRCodeFile4 = null; // 用于微信二维码4

// 获取公司信息
const fetchCompanyInfo = async () => {
  await store.dispatch('fetchCompanyInfo');
  Object.assign(companyInfo.value, store.getters.companyInfo);
  companyInfo.value.LogoPreview = companyInfo.value.Logo;
  companyInfo.value.WechatQRCodePreview1 = companyInfo.value.WechatQRCode1;
  companyInfo.value.WechatQRCodePreview2 = companyInfo.value.WechatQRCode2;
  companyInfo.value.WechatQRCodePreview3 = companyInfo.value.WechatQRCode3;
  companyInfo.value.WechatQRCodePreview4 = companyInfo.value.WechatQRCode4;
};

// Logo 上传处理
const handleLogoUpload = (event) => {
  logoFile = event.target.files[0];
  if (logoFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      companyInfo.value.LogoPreview = e.target.result;
    };
    reader.readAsDataURL(logoFile);
  }
};

// 微信二维码上传处理 - 1
const handleWechatQRCodeUpload1 = (event) => {
  wechatQRCodeFile1 = event.target.files[0];
  if (wechatQRCodeFile1) {
    const reader = new FileReader();
    reader.onload = (e) => {
      companyInfo.value.WechatQRCodePreview1 = e.target.result;
    };
    reader.readAsDataURL(wechatQRCodeFile1);
  }
};

// 微信二维码上传处理 - 2
const handleWechatQRCodeUpload2 = (event) => {
  wechatQRCodeFile2 = event.target.files[0];
  if (wechatQRCodeFile2) {
    const reader = new FileReader();
    reader.onload = (e) => {
      companyInfo.value.WechatQRCodePreview2 = e.target.result;
    };
    reader.readAsDataURL(wechatQRCodeFile2);
  }
};

// 微信二维码上传处理 - 3
const handleWechatQRCodeUpload3 = (event) => {
  wechatQRCodeFile3 = event.target.files[0];
  if (wechatQRCodeFile3) {
    const reader = new FileReader();
    reader.onload = (e) => {
      companyInfo.value.WechatQRCodePreview3 = e.target.result;
    };
    reader.readAsDataURL(wechatQRCodeFile3);
  }
};

// 微信二维码上传处理 - 4
const handleWechatQRCodeUpload4 = (event) => {
  wechatQRCodeFile4 = event.target.files[0];
  if (wechatQRCodeFile4) {
    const reader = new FileReader();
    reader.onload = (e) => {
      companyInfo.value.WechatQRCodePreview4 = e.target.result;
    };
    reader.readAsDataURL(wechatQRCodeFile4);
  }
};

// 表单提交处理
const handleSubmit = () => {
  const formData = new FormData();

  // 提交文本字段
  formData.append('company_intro', companyInfo.value.CompanyIntro);
  formData.append('whatsapp_number', companyInfo.value.WhatsappNumber);
  formData.append('contact_email', companyInfo.value.ContactEmail);
  formData.append('contact_email_2', companyInfo.value.ContactEmail2);
  formData.append('contact_email_3', companyInfo.value.ContactEmail3);
  formData.append('contact_email_4', companyInfo.value.ContactEmail4);
  formData.append('landline_number', companyInfo.value.LandlineNumber);

  // 无论是否有文件上传，都提交微信 ID 字段
  formData.append('wechat_id_1', companyInfo.value.WechatID1);
  formData.append('wechat_id_2', companyInfo.value.WechatID2);
  formData.append('wechat_id_3', companyInfo.value.WechatID3);
  formData.append('wechat_id_4', companyInfo.value.WechatID4);

  // 仅在有文件时，上传文件
  if (logoFile) {
    formData.append('logo', logoFile);
  }

  if (wechatQRCodeFile1) {
    formData.append('wechat_qr_code_1', wechatQRCodeFile1);
  }

  if (wechatQRCodeFile2) {
    formData.append('wechat_qr_code_2', wechatQRCodeFile2);
  }

  if (wechatQRCodeFile3) {
    formData.append('wechat_qr_code_3', wechatQRCodeFile3);
  }

  if (wechatQRCodeFile4) {
    formData.append('wechat_qr_code_4', wechatQRCodeFile4);
  }

  store.dispatch('updateCompanyInfo', { id: companyInfo.value.CompanyID, formData });
};

onMounted(() => {
  fetchCompanyInfo();
});
</script>

<style scoped>
.company-info-management {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 40px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: left;
}

h1 {
  text-align: left;
  color: #2c3e50;
  font-size: 26px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.form-group {
  margin-bottom: 20px;
}

.row-layout {
  display: flex;
  justify-content: flex-start; /* 对齐到左边 */
  align-items: flex-start; /* 保证 label 和 input 在顶部对齐 */
  gap: 10px; /* 间距设置为10px */
}

.column-layout {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #34495e;
  font-size: 15px;
}

textarea, input[type="email"] {
  width: calc(100% - 20px); /* 保证输入框不会溢出 */
  padding: 10px;
  font-size: 14px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  transition: border-color 0.3s;
}

input[type="text"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  transition: border-color 0.3s;
}

/* 单独设置微信输入框的宽度为 300px */
input.wechat-input {
  width: 300px;
}

textarea:focus, input[type="text"]:focus {
  border-color: #2980b9;
}

input[type="file"] {
  margin-top: 10px;
  font-size: 14px;
}

.preview-image {
  display: block;
  margin-top: 12px;
  max-width: 100px;
  height: auto;
  border: 1px solid #ddd;
  padding: 4px;
  border-radius: 4px;
  transition: transform 0.2s;
}

.preview-image:hover {
  transform: scale(1.03);
}

.image-tip {
  margin-top: 8px;
  font-size: 12px;
  color: #888;
}

/* 分隔符样式 */
.divider {
  border-top: 1px dashed #ccc;
  margin: 20px 0;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #2980b9;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: #1f618d;
}

button:active {
  transform: scale(0.98);
}
</style>
