import { getUserList, addUser, updateUser, deleteUser } from '@/api/api';
import { notifySuccess, notifyError } from '@/utils/notify';

const user = {
    state: () => ({
        userList: []
    }),
    mutations: {
        SET_USER_LIST(state, userList) {
            state.userList = userList;
        },
        ADD_USER(state, user) {
            state.userList.push(user);
        },
        UPDATE_USER(state, { id, updatedUser }) {
            const index = state.userList.findIndex(user => user.UserID === id);
            if (index !== -1) {
                state.userList[index] = updatedUser;
            }
        },
        DELETE_USER(state, id) {
            state.userList = state.userList.filter(user => user.UserID !== id);
        },
    },
    actions: {
        async fetchUserList({ commit }, { page, pageSize }) {
            try {
              const response = await getUserList(page, pageSize);
              commit('SET_USER_LIST', response.data.users);
              return response.data;
            } catch (error) {
              notifyError('获取用户列表失败');
              console.error('获取用户列表失败', error);
            }
          },
          
        async addUser({ commit }, user) {
            try {
                const response = await addUser(user);
                commit('ADD_USER', response.data);
                notifySuccess('用户添加成功');
            } catch (error) {
                notifyError('添加用户失败');
                console.error('添加用户失败', error);
            }
        },
        async updateUser({ commit }, { id, user }) {
            try {
                await updateUser(id, user);
                commit('UPDATE_USER', { id, updatedUser: user });
                notifySuccess('用户更新成功');
            } catch (error) {
                notifyError('更新用户失败');
                console.error('更新用户失败', error);
            }
        },
        async deleteUser({ commit }, id) {
            try {
                await deleteUser(id);
                commit('DELETE_USER', id);
                notifySuccess('用户删除成功');
            } catch (error) {
                notifyError('删除用户失败');
                console.error('删除用户失败', error);
            }
        },
    },
    getters: {
        userList: state => state.userList,
    },
};

export default user;
