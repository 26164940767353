<template>
    <div class="float-button-box" @click="navigateToContactPage">
      <!-- 编号: 100040001 按钮-图标 -->
      <div class="button-icon"></div>
    </div>
  </template>
  
  <script setup>
  import { useRouter } from 'vue-router';
  
  // 编号: 100040000 浮动按钮盒子
  const router = useRouter();
  
  function navigateToContactPage() {
    router.push({ name: 'ContactPage' }); // 导航到 ContactPage
  }
  </script>
  
  <style scoped>
  /* 编号: 100040000 浮动按钮盒子 */
  .float-button-box {
    position: fixed;
    bottom: 25vh;
    right: 20px;
    z-index: 10;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
  }
  
  /* 编号: 100040001 按钮-图标 */
  .button-icon {
    left: 322px;
    width: 53px;
    height: 39px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 1);
    background-image: url('../images/100040001.png');
    background-size: cover;
    background-position: center;
  }
  </style>
  