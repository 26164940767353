<template>
  <div class="message-board">
    <h2 class="message-board-title">{{ $t('message.messageBoard') }}</h2>
    <input v-model="country" class="country-input" type="text" :placeholder="$t('message.countryPlaceholder')">
    <input v-model="city" class="city-input" type="text" :placeholder="$t('message.cityPlaceholder')">
    <input v-model="email" class="email-input" type="email" :placeholder="$t('message.emailPlaceholder')">
    <input v-model="phone" class="phone-input" type="tel" :placeholder="$t('message.phonePlaceholder')">
    <textarea v-model="message" class="message-input" :placeholder="$t('message.messagePlaceholder')"></textarea>
    <button class="submit-button" @click="handleSubmit">{{ $t('message.submit1') }}</button>
  </div>
</template>

  
  <script setup>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import geoMixin from '../mixins/geoMixin.js';
  
  const store = useStore();
  
  const country = ref('');
  const city = ref('');
  const email = ref('');
  const phone = ref('');
  const message = ref('');
  
  geoMixin.methods.getIpGeolocation().then((geoData) => {
    if (geoData) {
      country.value = geoData.country || '';
      city.value = geoData.city || '';
    } else {
      console.error('未能获取到地理位置信息');
    }
  });
  
  const handleSubmit = async () => {
    try {
      const ipResponse = await axios.get('https://api64.ipify.org?format=json');
      const ip = ipResponse.data.ip;
      const userId = geoMixin.methods.getCachedUserId(ip);
      if (userId) {
        await store.dispatch('updateUserContact', {
          UserID: userId,
          Name: '',
          Email: email.value,
          Phone: phone.value,
          Country: country.value,
          City: city.value,
          MessageContent: message.value,
          AccessTime: new Date().toISOString(),
          FollowUp: false
        });
      } else {
        console.error('未能找到缓存的 UserID');
      }
    } catch (error) {
      console.error('获取 IP 或提交数据失败:', error);
    }
  };
  </script>
  
  <style scoped>
  .message-board {
    margin-top: 40px;
  }
  
  .message-board-title {
    margin-left: 28px;
    margin-top: 20px;
    height: 28px;
    line-height: 28px;
    color: rgba(0,0,0,1);
    font-size: 20px;
    text-align: left;
    font-family: PingFangSC-Regular;
  }
  
  .country-input,
  .city-input{
    display: block;
    width: 85%;
    margin: 10px auto;
    padding: 8px;
    border: 1px solid rgba(187, 187, 187, 1);
    border-radius: 4px;
  }
  .email-input,
  .message-input,
  .phone-input,
  .submit-button {
    display: block;
    width: 80%;
    margin: 10px auto;
    padding: 8px;
    border: 1px solid rgba(187, 187, 187, 1);
    border-radius: 4px;
  }
  
  .message-input {
    height: 100px;
  }
  
  .submit-button {
    margin-top: 20px;
    height: 35px;
    width: 85%;
    line-height: 17px;
    border-radius: 4px;
    background-color: rgba(0,0,0,1);
    color: rgba(255,255,255,0.95);
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    margin-bottom: 10vh;
  }
  </style>
  