import { getConfigurationsBySeriesID } from '@/api/api';
import { notifyError } from '@/utils/notify'; // 假设有一个通知工具

const seriesModule = {
  namespaced: true,
  state: () => ({
    products: [],
  }),
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
  },
  actions: {
    async fetchConfigurationsBySeriesID({ commit }, seriesID) {
      console.log(`Action: fetchConfigurationsBySeriesID called with seriesID: ${seriesID}`);
      try {
        const response = await getConfigurationsBySeriesID(seriesID);
        console.log('API response:', response.data);
        commit('SET_PRODUCTS', response.data);
      } catch (error) {
        notifyError('获取配置失败');
        console.error('获取配置失败', error);
      }
    },
  },
  
  getters: {
    products: (state) => state.products,
  },
};

export default seriesModule;
