// src/api/api.js
import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://www.etong-ev.com/api/yitong/', // 替换为你的后端地址
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  
  export const getTextImageDescList = (configId) => apiClient.get(`/text-image-desc/${configId}`);
  export const addTextImageDesc = (data) => apiClient.post('/text-image-desc', data);
  export const updateTextImageDesc = (id, data) => apiClient.put(`/text-image-desc/${id}`, data);
  export const deleteTextImageDesc = (id) => apiClient.delete(`/text-image-desc/${id}`);