import axios from 'axios';

export default {
  methods: {
    async getIpGeolocation() {
      try {
        const ipResponse = await axios.get('https://api64.ipify.org?format=json');
        const ip = ipResponse.data.ip;

        const geoFetchData = JSON.parse(localStorage.getItem('geoFetchData')) || {};
        const cachedGeoData = geoFetchData[ip];
        const now = Date.now();
        const interval = 1 * 60 * 60 * 1000; // 1小时

        if (cachedGeoData && now - cachedGeoData.timestamp < interval) {
          console.log('从缓存中获取地理位置信息');
          return cachedGeoData.geoData;
        }

        const geoResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
        const geoData = {
          country: geoResponse.data.country_name,
          city: geoResponse.data.city
        };

         // 插入用户数据并获取返回的ID
         const response = await axios.post('https://www.etong-ev.com/api/yitong/users', {
            country: geoData.country,
            ipAddress: geoResponse.data.ip,
            accessTime: new Date().toISOString(),
            city: geoData.city
          });
  
          const userId = response.data.UserID; // 假设后端返回的ID字段为UserID
  
          // 将ID和地理位置信息缓存下来
          geoFetchData[ip] = {
            timestamp: now,
            geoData: geoData,
            userId: userId // 缓存用户ID
          };
        localStorage.setItem('geoFetchData', JSON.stringify(geoFetchData));

        return geoData;
      } catch (error) {
        console.error('获取IP归属地失败:', error);
        return null;
      }
    },
    getCachedUserId(ip) {
        const geoFetchData = JSON.parse(localStorage.getItem('geoFetchData')) || {};
        const cachedGeoData = geoFetchData[ip];
        return cachedGeoData ? cachedGeoData.userId : null;
      }
  }
};
