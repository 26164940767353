<template>
  <footer class="footer">
    <!-- 编号: 100060001 微信图标 -->
    <div class="weixin-icon" @click="toggleQRCodeModal"></div>
    <!-- 编号: 100060002 官方微信 -->
    <span class="official-weixin" @click="toggleQRCodeModal">{{ companyInfo.WechatID }}</span>
    <!-- 编号: 100060003 微博图标 -->
    <div class="weibo-icon"></div>
    <!-- 编号: 100060004 官方微博 -->
    <span class="official-weibo">{{ companyInfo.WhatsappNumber }}</span>
    
    <!-- 二维码弹框 -->
    <div v-if="showQRCodeModal" class="modal-overlay" @click.self="toggleQRCodeModal">
      <div class="modal-content">
        <span class="close-button" @click="toggleQRCodeModal">&times;</span>
        <img :src="companyInfo.WechatQRCode" alt="微信二维码">
        <p class="qr-code-tip">{{ companyInfo.WhatsappNumber }}</p>
      </div>
    </div>
  </footer>
</template>


<script setup>
import { computed, ref , onMounted} from 'vue';
import { useStore } from 'vuex';

const showQRCodeModal = ref(false);
const store = useStore();
const companyInfo = computed(() => store.getters.companyInfo);

const toggleQRCodeModal = () => {
  showQRCodeModal.value = !showQRCodeModal.value;
};
onMounted(() => {
  store.dispatch('fetchCompanyInfo');
});
</script>


<style scoped>
.footer {
  position: relative;
  width: 100%;
  /* 底部盒子样式 */
}

/* 编号: 100060001 微信图标 */
.weixin-icon {
  position: absolute;
  left: 73px;
  top: 100px;
  width: 24px;
  height: 24px;
  background-image: url('@/images/100060001.png');
  background-size: cover;
}

/* 编号: 100060002 官方微信 */
.official-weixin {
  position: absolute;
  left: 103px;
  top: 100px;
  width: 72px;
  height: 24px;
  line-height: 19px;
  color: rgba(22, 22, 25, 1);
  font-size: 13px;
  text-align: center;
  font-family: PingFangSC-regular;
}

/* 编号: 100060003 微博图标 */
.weibo-icon {
  position: absolute;
  left: 218px;
  top: 100px;
  width: 24px;
  height: 24px;
  background-image: url('@/images/800010002.png');
  background-size: cover;
}

/* 编号: 100060004 官方微博 */
.official-weibo {
  position: absolute;
  left: 249px;
  top: 100px;
  width: 72px;
  height: 24px;
  line-height: 19px;
  color: rgba(22, 22, 25, 1);
  font-size: 13px;
  text-align: center;
  font-family: PingFangSC-regular;
}
</style>
<style scoped>
.footer {
  /* 添加 footer 样式 */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
}

.modal-content img {
  width: 150px;
  height: 150px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.qr-code-tip {
  margin-top: 15px;
  font-size: 16px;
  color: #333;
}
</style>
