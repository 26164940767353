<template>
  <div class="vehicle-management">
    <h1>{{ title }}</h1>
    <div class="input-group">
      <select v-model="selectedSeries" @change="fetchProductModels">
        <option value="" disabled>请选择系列</option>
        <option v-for="series in seriesList" :key="series.SeriesID" :value="series.SeriesID">
          {{ series.SeriesName }}
        </option>
      </select>
      <input v-model="newProductModel" placeholder="输入产品型号名称" />
      <button @click="handleAddProductModel" :disabled="!selectedSeries">保存</button>
    </div>
    <div class="table-container">
      <p v-if="!selectedSeries" class="prompt-message">
        提示：请点击上边系列下拉框选择系列，在右边输入框新增新的产品
      </p>
      <table v-if="selectedSeries && productModelList.length">
        <thead>
          <tr>
            <th>序号</th>
            <th>产品型号</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(model, index) in productModelList" :key="model.ModelID">
            <td>{{ index + 1 }}</td>
            <td>
              <input v-model="model.ProductModel" />
            </td>
            <td>
              <!-- <button @click="handleUpdateProductModel(model.ModelID, model)">修改</button> -->
              <button @click="handleUpdateProductModel(model.ModelID, model)" :disabled="isSaving">修改</button>
              <button class="delete-btn" @click="handleDeleteProductModel(model.ModelID)">删除</button>
              <button class="save-btn" @click="toggleConfigProduct(model)">产品配置</button>
            </td>
          </tr>
          <tr v-if="showConfig && selectedModel" :key="'config-' + selectedModel.ModelID">
            <td colspan="5">
              <div class="config-table-header">
                <h2>产品配置管理 - {{ selectedModel.ProductModel }}</h2>
                <button class="save-btn" @click="handleAddConfigRow">新增配置</button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>配置编码</th>
                    <th>图片</th>
                    <th>最低价格</th>
                    <th>最高价格</th>
                    <th>操作</th>
                    <th>详情页管理</th>
                    <th>参数管理</th>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(config, configIndex) in configList" :key="config.ConfigID">
                    <td>
                      <input v-model="config.Configuration" placeholder="输入产品配置" />
                    </td>
                    <td> 
                      <img :src="config.ProductImage ? config.ProductImage : ''" v-if="config.ProductImage" style="width: 100px; height: 100px;">
                      <input type="file" ref="'fileInput' + configIndex" @change="event => handleFileChange(event, configIndex)">
                    </td>
                    <td>
                      <input v-model="config.MinPrice" placeholder="输入产品价格" type="number" />
                    </td>
                    <td>
                      <input v-model="config.MaxPrice" placeholder="输入产品价格" type="number" />
                    </td>
                    <td>
                      <button class="save-btn" @click="handleSaveConfig(configIndex)" :disabled="isSaving">
                        {{ isSaving ? '保存中...' : '保存' }}
                      </button>
                      <button class="delete-btn" @click="handleDeleteConfig(config.ConfigID)" :disabled="isDeleting">删除</button>
                      <p v-if="isDeleting" class="loading-message">删除中，请稍候...</p>
                    </td>
                    <td>
                      <button class="save-btn" @click="openDetailModal(config)">详情页管理</button>
                    </td>
                    <td>
                      <button class="save-btn" @click="handleParameterManagement(config.ConfigID)">参数管理</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 模态框 -->
    <teleport to="body">
      <div v-if="isDetailModalVisible" class="modal-overlay">
        <div class="modal">
          <DetailModal :config="selectedConfig" @close="closeDetailModal" />
        </div>
      </div>
    </teleport>
    <teleport to="body">
      <div v-if="isParameterModalVisible" class="modal-overlay">
        <div class="modal">
          <VehicleParameterForm :configId="selectedConfigId" @close="closeParameterModal" />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
// import axios from 'axios';
import DetailModal from './VehicleManagement/DetailModal.vue'; // 导入子组件用于详情页管理
import VehicleParameterForm from './VehicleParameterForm.vue';
// import { VueFinalModal } from 'vue-final-modal'; // 导入 vue-final-modal 组件
import '@/assets/table.css';

const store = useStore();
const seriesList = computed(() => store.getters.seriesList);// 获取系列列表
const productModelList = computed(() => store.getters.productModelList); // 获取产品型号列表
const configList = computed(() => store.getters.configurationList);// 获取配置列表
const selectedSeries = ref('');// 选中的系列
const newProductModel = ref(''); // 新增的产品型号
const title = ref('车辆管理');
const showConfig = ref(false);// 是否显示配置管理
const selectedModel = ref(null);// 选中的产品型号
const selectedConfig = ref({ ProductModel: '' }); // 选中的配置，添加默认值
const isDetailModalVisible = ref(false); // 详情弹框是否可见
const selectedConfigId = ref(null); // 用于存储选中的 ConfigID
const isParameterModalVisible = ref(false);
const isSaving = ref(false); // 用于控制保存状态
const isDeleting = ref(false);


const handleParameterManagement = (configId) => {
  console.log('999:',configId)
  selectedConfigId.value = configId;
  isParameterModalVisible.value = true;
};

const closeParameterModal = () => {
  isParameterModalVisible.value = false;
};
const openDetailModal = (config) => {
  selectedConfig.value = config;
  isDetailModalVisible.value = true; // 打开详情页管理弹框
};

const closeDetailModal = () => {
  isDetailModalVisible.value = false;
  selectedConfig.value = { ProductModel: '', DetailImage: '', DetailDescription: '' }; // 关闭详情页管理弹框，重置选中的配置
};

const fetchSeriesList = () => {
  store.dispatch('fetchSeriesList');
};

const fetchProductModels = () => {
  if (selectedSeries.value) {
    store.dispatch('fetchProductModels', selectedSeries.value);
  }
};


const fetchConfigurations = (modelId) => {
      store.dispatch('fetchConfigurations', modelId);
    };

const handleAddProductModel = () => {
  if (newProductModel.value.trim() !== '') {
    store.dispatch('addProductModel', { SeriesID: selectedSeries.value, ProductModel: newProductModel.value });
    newProductModel.value = '';
  }
};

const handleUpdateProductModel = async (id, model) => {
  if (isSaving.value) return; // 如果已经在保存中，则不执行
  isSaving.value = true;
  try {
    await store.dispatch('updateProductModel', { id, model });
  } catch (error) {
    console.error('修改产品型号失败', error);
  } finally {
    isSaving.value = false;
  }
};

const handleDeleteProductModel = (id) => {
    store.dispatch('deleteProductModel', id);
};

const toggleConfigProduct = (model) => {
  showConfig.value = selectedModel.value !== model || !showConfig.value;
  selectedModel.value = showConfig.value ? model : null;
  if (showConfig.value) {
    fetchConfigurations(model.ModelID);
  }
};

const handleAddConfigRow = () => {
  configList.value.push({ ConfigID: null, ModelID: selectedModel.value.ModelID, Configuration: '', ProductImage: '', Price: '' });
};

const handleSaveConfig = async (index) => {
  if (isSaving.value) return; // 如果已经在保存中，则不执行
  isSaving.value = true;
  try {
    const config = configList.value[index];
    const formData = new FormData();
    formData.append('model_id', config.ModelID);
    formData.append('configuration', config.Configuration);
    formData.append('max_price', parseFloat(config.MaxPrice));
    formData.append('min_price', parseFloat(config.MinPrice));
    if (config.ProductImageFile) {
      formData.append('product_image', config.ProductImageFile);
    }
    await store.dispatch('saveConfiguration', { formData, configID: config.ConfigID });
  } catch (error) {
    console.error('保存配置失败', error);
  } finally {
    isSaving.value = false;
  }
};


const handleDeleteConfig = async (configId) => {
  if (confirm("确认要删除这个产品配置吗？")) {
    isDeleting.value = true; // 开始删除，设置状态为删除中
    try {
      await store.dispatch('deleteConfiguration', configId); // 执行删除操作
    } catch (error) {
      console.error("删除配置失败", error);
    } finally {
      isDeleting.value = false; // 删除完成，恢复状态
    }
  }
};


const  handleFileChange = async (event, index) => {
  const file = event.target.files[0];
  if (file) {
    // 创建一个 FileReader 对象
    const reader = new FileReader();
    // 设置 FileReader 的 onload 事件
    reader.onload = (e) => {
      // 更新本地预览图片
      configList.value[index].ProductImage = e.target.result;
      configList.value[index].ProductImageFile = file;
    };
    // 读取文件为 DataURL
    reader.readAsDataURL(file);
  }
};
// const  DetailImageChange = async (event, index) => {
//   const file = event.target.files[0];
//   if (file) {
//     // 创建一个 FileReader 对象
//     const reader = new FileReader();
//     // 设置 FileReader 的 onload 事件
//     reader.onload = (e) => {
//       // 更新本地预览图片
//       configList.value[index].DetailImage = e.target.result;
//       configList.value[index].DetailImageFile = file;
//     };
//     // 读取文件为 DataURL
//     reader.readAsDataURL(file);
//   }
// };



onMounted(() => {
  fetchSeriesList();
});
</script>

<style scoped>
.vehicle-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.input-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"], input[type="number"], select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
}

button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #45a049;
}

.prompt-message {
  text-align: center;
  color: #888;
  margin-top: 20px;
}

.table-container {
  text-align: center;
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.config-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.config-input-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.config-input-group > button,
.config-input-group > input {
  margin: 10px;
}

input[type="text"], input[type="number"] {
  width: calc(50% - 20px);
}

.other-operations {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.other-operations button {
  margin: 0 10px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 61vw; /* 设置模态框的最大宽度为屏幕宽度的80% */
  max-height: 80vh; /* 设置模态框的最大高度为屏幕高度的80% */
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto; /* 使模态框内容可滚动 */
}


</style>
