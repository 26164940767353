<template>
  <div class="series-management">
    <h1>{{ title }}</h1>
    <div class="input-group">
      <input v-model="newSeries" placeholder="输入系列名" />
      <button @click="handleAddSeries">新增系列名</button>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>系列名</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(series, index) in seriesList" :key="series.SeriesID">
            <td>{{ index + 1 }}</td>
            <td>
              <input v-model="series.SeriesName" />
            </td>
            <td>
              <button class="save-btn" @click="handleUpdateSeries(series.SeriesID, series)">保存</button>
              <button class="delete-btn" @click="handleDeleteSeries(series.SeriesID)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import '@/assets/table.css';
// import { getDataListByNew } from '../services/apiService';

const store = useStore();
const seriesList = computed(() => store.getters.seriesList);
const newSeries = ref('');
const title = ref('系列管理'); // 标题

const fetchSeriesList = () => {
  store.dispatch('fetchSeriesList');
};

const handleAddSeries = () => {
  if (newSeries.value.trim() !== '') {
    store.dispatch('addSeries', { SeriesName: newSeries.value });
    newSeries.value = '';
  }
};

const handleUpdateSeries = (id, series) => {
  store.dispatch('updateSeries', { id, series });
};

const handleDeleteSeries = (id) => {
  store.dispatch('deleteSeries', id);
};

onMounted(() => {
  fetchSeriesList();
});
</script>

<style scoped>
.series-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.input-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
}

button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}
</style>
