<template>
  <div class="product-main-box">
    <!-- 编号: 200030001 产品图片 -->
    <img :src="ProductImage" alt="Car Background" class="product-image" />
    
    <!-- 编号: 200030002 产品型号名字 -->
    <div class="product-model-name">{{ Configuration }}</div>
    
    <!-- 动态生成的数据部分 -->
    <div v-for="(item, index) in vehicleConfigData" :key="index">
      <div :class="'data-name-' + (index + 1)">{{ item.parameter_name }}</div>
      <div :class="'data-value-' + (index + 1)">{{ item.parameter_value }}</div>
      <div v-if="index % 2 === 0" :class="'vertical-divider-' + (index / 2 + 1)"></div>
    </div>
    
    <!-- 编号: 200030013 更多数据按钮 -->
    <button class="more-data-button" @click="goToVehicleData">{{ $t('message.moreData') }}</button>
    
    <!-- 编号: 200030014 在线询价按钮 -->
    <button class="inquiry-button" @click="navigateToInquiry">{{ $t('message.inquiry') }}</button>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { defineProps, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// 接收来自父组件的 props
const props = defineProps({
  ProductImage: {
    type: String,
    required: true
  },
  Configuration: {
    type: String,
    required: true
  },
  configID: {
    type: Number,
    required: true
  }
});

const router = useRouter();
const store = useStore();

// 获取 Vuex 中的车辆配置数据
const vehicleConfigData = computed(() => store.getters['vehicleConfig/vehicleConfigData']);

// 在组件挂载时调用 API 获取数据
onMounted(() => {
  store.dispatch('vehicleConfig/fetchVehicleConfig', props.configID);
});

function goToVehicleData() {
  console.log('props.ProductImage:',props.ProductImage)
  router.push({
    name: 'VehicleData',
    params: { configID: props.configID, ProductImage: props.ProductImage } // 传递 ProductImage 作为查询参数
  });
}

function navigateToInquiry() {
  router.push({ name: 'InquiryPage', params: { configID: props.configID } });
}

</script>

<style scoped>
/* 样式代码 */
</style>


<style scoped>
/* 编号: 200030000 产品主要介绍盒子 */
.product-main-box {
  position: relative;
  width: 100%;
  height:650px;
}

/* 编号: 200030001 产品图片 */
.product-image {
  position: absolute;
  left: 95px;
  top: 142px; /* 原为 942px，减少 800 */
  width: 205px;
  height: 182.69px;
  background-color: rgba(229,229,229,1);
  background-size: cover; /* 确保图片填满容器 */
  background-position: center; /* 图片居中显示 */
}

/* 编号: 200030002 产品型号名字 */
.product-model-name {
  position: absolute;
  left: 141px;
  top: 338px; /* 原为 1138px，减少 800 */
  width: 134px;
  height: 20px;
  line-height: 20px;
  color: rgba(22,22,25,1);
  font-size: 14px;
  text-align: left;
  font-family: PingFangSC-regular;
}

/* 编号: 200030003 数据名1 */
.data-name-1 {
  position: absolute;
  left: 83px;
  top: 401px; /* 原为 1201px，减少 800 */
  width: 102px;
  height: 17px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
}

/* 编号: 200030004 数据1 */
.data-value-1 {
  position: absolute;
  left: 105px;
  top: 423px; /* 原为 1223px，减少 800 */
  width: 79px;
  height: 17px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-bold;
}

/* 编号: 200030005 竖分割线1 */
.vertical-divider-1 {
  position: absolute;
  left: 195px;
  top: 401px; /* 原为 1201px，减少 800 */
  height: 38px;
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(83,83,83,1);
}

/* 编号: 200030006 数据名2 */
.data-name-2 {
  position: absolute;
  left: 207px;
  top: 399px; /* 原为 1199px，减少 800 */
  width: 102px;
  height: 20px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
}

/* 编号: 200030007 数据2 */
.data-value-2 {
  position: absolute;
  left: 208px;
  top: 423px; /* 原为 1223px，减少 800 */
  width: 80px;
  height: 19px;
  line-height: 19px;
  color: rgba(22,22,25,1);
  font-size: 13px;
  text-align: left;
  font-family: PingFangSC-bold;
}

/* 编号: 200030008 数据名3 */
.data-name-3 {
  position: absolute;
  left: 83px;
  top: 455px; /* 原为 1255px，减少 800 */
  width: 102px;
  height: 20px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
}

/* 编号: 200030009 数据3 */
.data-value-3 {
  position: absolute;
  left: 105px;
  top: 477px; /* 原为 1277px，减少 800 */
  width: 79px;
  height: 17px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-bold;
}

/* 编号: 200030010 竖分割线2 */
.vertical-divider-2 {
  position: absolute;
  left: 196px;
  top: 456px; /* 原为 1256px，减少 800 */
  height: 38px;
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(83,83,83,1);
}

/* 编号: 200030011 数据名4 */
.data-name-4 {
  position: absolute;
  left: 208px;
  top: 455px; /* 原为 1255px，减少 800 */
  width: 102px;
  height: 20px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-regular;
}

/* 编号: 200030012 数据4 */
.data-value-4 {
  position: absolute;
  left: 208px;
  top: 477px; /* 原为 1277px，减少 800 */
  width: 79px;
  height: 17px;
  line-height: 17px;
  color: rgba(22,22,25,1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-bold;
}

/* 编号: 200030013 更多数据按钮 */
.more-data-button {
  position: absolute;
  left: 57px;
  top: 537px; /* 原为 1337px，减少 800 */
  width: 128px;
  height: 28px;
  line-height: 17px;
  border-radius: 30px;
  background-color: rgba(255,255,255,1);
  color: rgba(16,16,16,1);
  font-size: 12px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(187,187,187,1);
}

/* 编号: 200030014 在线询价按钮 */
.inquiry-button {
  position: absolute;
  left: 207px;
  top: 537px; /* 原为 1337px，减少 800 */
  width: 128px;
  height: 28px;
  line-height: 17px;
  border-radius: 30px;
  background-color: rgba(255,255,255,1);
  color: rgba(16,16,16,1);
  font-size: 12px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(187,187,187,1);
}


</style>
