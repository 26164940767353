// store/modules/carData.js

import api from '@/api/api';// 引入封装的 API 模块

export default {
  // 命名空间
  namespaced: true,
  
  // 状态
  state: {
    carDataList: [] // 用于存储系列配置数据
  },

  // 同步更新状态的 mutations
  mutations: {
    // 更新 carDataList 状态
    setCarDataList(state, carDataList) {
      state.carDataList = carDataList;
    }
  },

  // 异步操作和业务逻辑的 actions
  actions: {
    // 异步获取系列配置数据
    async fetchCarDataList({ commit }) {
      try {
        // 调用 API 获取系列配置数据
        const response = await api.getSeriesConfigurations();
        // 提交 mutation 更新状态
        commit('setCarDataList', response.data);
      } catch (error) {
        // 错误处理
        console.error('获取系列配置数据时发生错误:', error);
      }
    }
  },

  // 从状态中派生出数据的 getters
  getters: {
    // 返回 carDataList 数据
    carDataList: state => state.carDataList
  }
};
