import { getVehicleParameters,createOrUpdateVehicleParameters } from '@/api/api';
import { notifySuccess, notifyError } from '@/utils/notify';

const vehicleParametersModule = {
  state: () => ({
    vehicleParameters: [],
  }),
  mutations: {
    SET_VEHICLE_PARAMETERS(state, parameters) {
      state.vehicleParameters = parameters;
    },
    UPDATE_PARAMETER_VALUE(state, { parameterId, parameterValue }) {
      const parameter = state.vehicleParameters.find(param => param.parameter_id === parameterId);
      if (parameter) {
        parameter.parameter_value = parameterValue;
      }
    },
  },
  actions: {
    async fetchVehicleParameters({ commit }, configId) {
      try {
        const response = await getVehicleParameters(configId);
        commit('SET_VEHICLE_PARAMETERS', response.data);
      } catch (error) {
        notifyError('获取车辆参数失败');
        console.error('获取车辆参数失败', error);
      }
    },
    // 正确的Vuex action签名
    async saveVehicleParameters(_, { configId, paramsToSubmit }) { // 使用 _ 替代空对象模式
        console.log('configId:', configId);
        try {
          const parameters = paramsToSubmit;
          await createOrUpdateVehicleParameters(configId, parameters);
          notifySuccess('车辆参数保存成功');
        } catch (error) {
          notifyError('保存车辆参数失败');
          console.error('保存车辆参数失败', error);
        }
    },
    updateParameterValue({ commit }, { parameterId, parameterValue }) {
      commit('UPDATE_PARAMETER_VALUE', { parameterId, parameterValue });
    },
  },
  getters: {
    vehicleParameters: state => state.vehicleParameters,
  },
};

export default vehicleParametersModule;
