<template>
  <!-- 产品标题 -->
  <div class="car-title">{{ $t('message.product') }}</div>
  <div class="car-display">
    <!-- 动态生成的车项 -->
    <div
      v-for="(car, index) in cars"
      :key="car.id"
      :class="['car-item', { 'first-item': index === 0 }]"
      @click="goToViewAll(car.id, car.name)"
    >
      <div class="car-box">
        <img :src="car.imgSrc" :alt="car.name" class="car-img" />
      </div>
      <div class="car-name">{{ car.name }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';

defineProps({
  cars: {
    type: Array,
    required: true
  },
  isLazy: {
    type: Boolean,
    default: false
  }
});

const router = useRouter();

const goToViewAll = (seriesID, modelName) => {
  router.push({ 
    name: 'ViewAllPage', 
    params: { seriesID }, 
    query: { title: '产品系列', modelName } // 在 query 中传递 modelName
  });
};

</script>

<style scoped>
/* 产品标题样式 */
.car-title {
  position: relative; /* 确保标题不被覆盖 */
  z-index: 5; /* 让标题显示在车项上方 */
  font-size: 23px;
  font-weight: bold;
  color: #000000;
  text-align: left;
  padding-left: 4vh;
  margin-bottom: 1vh; /* 标题与车项之间的间距 */
}

.car-display {
  position: relative; /* 调整为相对位置 */
  width: 100%;
  max-height: clamp(22vh, 30vh, 500px); 
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  margin-bottom: 10vh;
  -webkit-overflow-scrolling: touch;
  z-index: 4;
    /* 隐藏滚动条 */
    scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 和 Edge */
}
.car-item {
  flex: 0 0 clamp(86px, 20vw, 120px); 
  text-align: center;
  padding: 1.5vw; /* 减少内边距 */
  margin-left: 1.5vw; /* 距离左边有点间距 */
}
.first-item {
  margin-left: 2.5vh; /* 第一个盒子距离左边30px */
}

.car-box {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0.8vw 1.6vw rgba(0, 0, 0, 0.2);
  aspect-ratio: 3.1 / 4; /* 固定宽高比为4:3 */
  display: flex;
  align-items: center;
  justify-content: center;
}


.car-img {
  width: 100%; /* 图片占满整个盒子 */
  height: auto; /* 使图片的高度自动调整以保持比例 */
  object-fit: contain; /* 确保图片不会被裁剪 */
}

.car-name {
  margin-top: 1.2vh;
  font-size: 14px; /* 较小的字体 */
  color: #000000; /* 白色字体 */
  font-family: PingFangSC-regular;
}
</style>
