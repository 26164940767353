<template>
  <div class="detail-modal">
    <div class="header">
      <h2>页面详情管理 - {{ config.Configuration }}</h2>
      <button class="close-btn" @click="$emit('close')">关闭</button>
    </div>
    <div class="content">
      <div class="image-container">
        <img :src="config.DetailImage ? config.DetailImage : ''" class="product-image" />
      </div>
      <div class="file-input-container">
        <input type="file" @change="handleFileChange">
      </div>
      <div class="description-container">
        <input v-model="config.DetailDescription" placeholder="输入详情页简介" />
      </div>
      <div class="button-container">
         <button class="save-btn" @click="handleSaveConfig" :disabled="isSaving">
           {{ isSaving ? '保存中...' : '保存' }}
         </button>
      </div>
    </div>
    <PerformanceManagement :configId="config.ConfigID" />
    <TextImageManagement :configId="config.ConfigID" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { defineProps } from 'vue';
import PerformanceManagement from './PerformanceManagement.vue';
import TextImageManagement from './TextImageManagement.vue'; 
import { useStore } from 'vuex';

const store = useStore();

// 使用 defineProps 定义接收的 props
const props = defineProps({
  config: {
    type: Object,
    default: () => ({ ProductModel: '', DetailImage: '', DetailDescription: '' }) // 添加默认值
  }
});

const config = ref(props.config); // 将传递的配置对象绑定到本地变量
const isSaving = ref(false); // 用于控制保存状态

// 监听 props.config 变化
watch(() => props.config, (newConfig) => {
  config.value = newConfig;
});

// 方法
const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      config.value.DetailImage = e.target.result;
      config.value.DetailImageFile = file;
    };
    reader.readAsDataURL(file);
  }
};

const handleSaveConfig = async () => {
  if (isSaving.value) return; // 防止重复保存
  isSaving.value = true;
  try {
    const formData = new FormData();
    formData.append('model_id', config.value.ModelID);
    formData.append('detail_description', config.value.DetailDescription);
    if (config.value.DetailImageFile) {
      formData.append('detail_image', config.value.DetailImageFile);
    }
    await store.dispatch('saveConfiguration1', { formData, configID: config.value.ConfigID });
  } catch (error) {
    console.error('保存配置失败', error);
  } finally {
    isSaving.value = false;
  }
};
</script>

<style scoped>
.detail-modal {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 60vw; /* 调整模态框的宽度 */
  height: 80vh; /* 调整模态框的高度 */
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* 使模态框内容可滚动 */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
}

.close-btn {
  position: fixed; /* 固定定位 */
  background-color: #f44336;
  color: white;
  padding: 8px 16px;
  right: 20vw;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-btn:hover {
  background-color: #d32f2f;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  margin-bottom: 20px;
  width: 100%;
  max-width: 120px; /* 根据需求调整最大宽度 */
  aspect-ratio: 394 / 780; /* 设置宽高比例 */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f0f0f0;
  overflow: hidden; /* 确保图片不会溢出容器 */
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持比例并填满容器 */
}

.file-input-container {
  margin-bottom: 20px;
}

.description-container {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.description-container input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
}

.save-btn {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.save-btn:hover:not(:disabled) {
  background-color: #45a049;
}
</style>
