<template>
  <div class="vehicle-parts-page">
    <!-- 加载指示器 -->
    <div v-if="isLoading" class="loading-indicator">
      <div class="spinner"></div>
      <div>{{ $t('message.loading') }}</div>
    </div>

    <!-- 动态渲染水平滑动盒子 -->
    <div v-else>
      <div style="height: 100px;"></div>
      <div v-for="(data, index) in carDataList" :key="index" style="margin-bottom: 3vh;">
        <HorizontalScrollBox :cars="data.cars" :title="data.title" :seriesID="data.seriesID"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import HorizontalScrollBox from '../components/HorizontalScrollBox.vue';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';


const isLoading = ref(true);

// 获取 store 实例
const store = useStore();

// 使用 computed 获取 carDataList 以确保响应式
const carDataList = computed(() => store.getters['carData/carDataList']);

// 在组件挂载时，获取系列配置数据
onMounted(async () => {
  try {
    await store.dispatch('carData/fetchCarDataList');
  } catch (error) {
    console.error('数据加载失败:', error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style scoped>
.vehicle-parts-page {
  position: relative;
  background-color: #ffffff; /* 设置背景为黑色 */
  color: #000000; /* 设置文字为白色 */
  min-height: 100vh; /* 确保页面最小高度占满整个视窗 */
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-size: 18px;
  color: #000000; /* 加载指示器文字设置为白色 */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.1); 
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
