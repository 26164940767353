<template>
    <div>
      <!-- 编号: 100010000 顶部组件 -->
      <HeaderComponent @toggle-side-nav="toggleSideNav" />
      <SideNavComponent :isVisible="isSideNavVisible" @close-side-nav="toggleSideNav" />
      <!-- 编号: 100040000 浮动按钮盒子 -->
      <FloatButtonBox />
      <!-- 渲染子组件 -->
      <router-view />

      <!-- 编号: 100050000 底部栏组件 -->
      <BottomBar />
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import HeaderComponent from '../components/MainHeader.vue';
  import SideNavComponent from '../components/SideNavComponent.vue';
  import FloatButtonBox from '../components/FloatButtonBox.vue';
  import BottomBar from '../components/BottomBar.vue'; // 引入新的底部栏组件
  
  // 状态管理
  const isSideNavVisible = ref(false);
  const router = useRouter();
  
  // 方法定义
  const toggleSideNav = () => {
    isSideNavVisible.value = !isSideNavVisible.value;
  };
  
  // 监听路由变化，自动收回侧边栏
  watch(
    () => router.currentRoute.value,
    () => {
      isSideNavVisible.value = false;
    }
  );
  </script>
  
  <style>
    body, html {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  </style>
  