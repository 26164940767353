import { getTextImageDescList, addTextImageDesc, updateTextImageDesc, deleteTextImageDesc } from '@/api/ImageApi';
import { notifySuccess, notifyError } from '@/utils/notify';

const textImageModule = {
  state: () => ({
    textImageList: [],
  }),
  mutations: {
    SET_TEXT_IMAGE_LIST(state, textImageList) {
      state.textImageList = textImageList;
    },
    ADD_TEXT_IMAGE_ROW(state, newRow) {
      state.textImageList.push(newRow);
    },
    UPDATE_TEXT_IMAGE_DESC(state, { id, updatedItem }) {
      const index = state.textImageList.findIndex(item => item.TextImageID === id);
      if (index !== -1) {
        state.textImageList[index] = updatedItem;
      }
    },
    DELETE_TEXT_IMAGE_DESC(state, id) {
      state.textImageList = state.textImageList.filter(item => item.TextImageID !== id);
    },
  },
  actions: {
    async fetchTextImageList({ commit }, configId) {
      try {
        const response = await getTextImageDescList(configId);
        commit('SET_TEXT_IMAGE_LIST', response.data);
      } catch (error) {
        notifyError('获取图文列表失败');
        console.error('获取图文列表失败', error);
      }
    },
    async addTextImageDesc({ commit }, item) {
      try {
        const formData = new FormData();
        formData.append('ConfigID', item.ConfigID);
        formData.append('OtherText', item.OtherText);
        if (item.File) {
          formData.append('OtherImage', item.File);
        }
        const response = await addTextImageDesc(formData);
        commit('ADD_TEXT_IMAGE_ROW', response.data);
        notifySuccess('图文添加成功');
      } catch (error) {
        notifyError('添加图文失败');
        console.error('添加图文失败', error);
      }
    },
    async updateTextImageDesc({ commit }, { id, item }) {
      try {
        const formData = new FormData();
        formData.append('ConfigID', item.ConfigID);
        formData.append('OtherText', item.OtherText);
        if (item.File) {
          formData.append('OtherImage', item.File);
        }
        const response = await updateTextImageDesc(id, formData);
        commit('UPDATE_TEXT_IMAGE_DESC', { id, updatedItem: response.data });
        notifySuccess('图文更新成功');
      } catch (error) {
        notifyError('更新图文失败');
        console.error('更新图文失败', error);
      }
    },
    async deleteTextImageDesc({ commit }, id) {
      try {
        await deleteTextImageDesc(id);
        commit('DELETE_TEXT_IMAGE_DESC', id);
        notifySuccess('图文删除成功');
      } catch (error) {
        notifyError('删除图文失败');
        console.error('删除图文失败', error);
      }
    },
  },
  getters: {
    textImageList: state => state.textImageList,
  },
};

export default textImageModule;
