<template>
  <div class="view-all-page">
    <div style="height: 120px;"></div>
    <!-- 500000000 具体系列标题 -->
    <div class="series-title">{{ parseTitle(title, locale) }}</div>

    <!-- 500010000 产品盒子 -->
    <div class="products-container">
      <!-- 遍历产品型号 -->
      <div
        class="product-model"
        v-for="(model, modelIndex) in products"
        :key="modelIndex"
        :id="model.modelName"
      >
        <!-- 500010003 产品型号名字 -->
        <div class="product-model-name">{{ model.modelName }}</div>

        <!-- 遍历产品配置 -->
        <div
          class="product-box"
          v-for="(config, configIndex) in model.configurations"
          :key="configIndex"
          @click="goToCarDetailsPage(config.configID)"
        >
          <!-- 500010001 产品图片外部容器 -->
          <div class="product-image-wrapper">
            <img
              class="product-image"
              :src="config.image"
              :alt="config.configName"
            />
            <div class="product-info">
              <div class="product-name">{{ config.configName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { parseTitle } from '@/utils/i18nHelper';

const { locale } = useI18n();  // 获取当前语言环境

const router = useRouter();
const route = useRoute();
const store = useStore();

const seriesID = route.params.seriesID;
const title = route.query.title;
const targetModelName = route.query.modelName; 
const products = computed(() => store.getters['series/products']);


// 跳转到详情页面，传递 configID
const goToCarDetailsPage = (configID) => {
  router.push({ name: 'CarDetailsPage', params: { configID } });
};

// 组件挂载时获取数据并滚动
onMounted(() => {
  store.dispatch('series/fetchConfigurationsBySeriesID', seriesID).then(() => {
    // 使用 nextTick 确保 DOM 已完全渲染后再进行滚动操作
    nextTick(() => {
      const targetElement = document.getElementById(targetModelName);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  });
});
</script>

<style scoped>
/* 500000000 具体系列标题 */
.series-title {
  margin-bottom: 20px;
  color: rgba(33, 33, 33, 1);
  font-size: 24px;
  text-align: left;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  margin-left: 6vw;
}

/* 500010000 产品盒子容器 */
.products-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 产品盒子之间的间距 */
  padding: 0 15px;
  margin-bottom: 10vh;
}

/* 500010000 产品型号容器 */
.product-model {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 水平和垂直方向的间隙 */
}

/* 500010003 产品型号名字 */
.product-model-name {
  margin-bottom: 15px;
  color: rgba(44, 44, 44, 0.85);
  font-size: 20px;
  text-align: left;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  width: 100%;
  margin-left: 2vw;
}

/* 500010000 产品盒子 */
.product-box {
  flex-basis: calc(33.33% - 20px); /* 将每个盒子宽度设置为三分之一，并考虑间距 */
  max-width: calc(33.33% - 20px); /* 确保宽度限制为三分之一 */
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px; /* 为盒子底部添加间距，避免紧贴 */
}

.product-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  flex-basis: calc(50% - 20px); 
  max-width: calc(50% - 20px);
}

/* 响应式布局 */
@media (max-width: 768px) {
  .product-box {
    flex-basis: calc(50% - 10px); /* 在平板和中等设备上两列布局 */
    max-width: calc(50% - 10px);
  }
}

/* 响应式布局 */
@media (max-width: 480px) {
  .product-box {
    flex-basis: calc(50% - 4vw); /* 在小屏设备上一行两列布局 */
    max-width: calc(50% - 4vw); /* 设置为一行两个盒子，并考虑水平间隙 */
  }
}



/* 产品图片外部容器 */
.product-image-wrapper {
  padding: 4vw; /* 添加内边距 */
  box-sizing: border-box;
  position: relative;
}

/* 500010001 产品图片 */
.product-image {
  width: 100%;
  height: auto;
  object-fit: contain; /* 确保图片完整显示，保持比例 */
  border-radius: 8px; /* 为图片添加圆角 */
  display: block;
}

/* 500010002 产品名字 */
.product-info {
  bottom: 0;
  width: 120%;
  background-color: rgba(0, 0, 0, 0.6); /* 半透明黑色背景 */
  padding: 0.5vh 1vh; /* 增加内边距 */
  box-sizing: border-box;
}

.product-name {
  color: white;
  font-size: 18px; /* 增大字体 */
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 600; /* 加粗字体 */
  letter-spacing: 0.5px; /* 增加字距 */
  text-align: center; /* 文字居中 */
}
</style>
