<template>
    <div class="login-container">
      <h2>管理员登录</h2>
        <div class="input-group">
          <label for="username">用户名</label>
          <input type="text" id="username" v-model="username" required />
        </div>
        <div class="input-group">
          <label for="password">密码</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button @click="handleLogin">登录</button>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  
  const store = useStore();
  const router = useRouter();
  const username = ref('');
  const password = ref('');
  
  const isSubmitting = ref(false);  // 防止重复提交的标志

  const handleLogin = async () => {
    if (username.value.trim() && password.value.trim() && !isSubmitting.value) {
      isSubmitting.value = true;  // 设置标志，防止重复提交
      try {
        await store.dispatch('adminLogin', { username: username.value, password: password.value });
        router.push('/admin/series-management');
      } catch (error) {
        console.error('登录失败', error);
      } finally {
        isSubmitting.value = false;  // 完成后恢复提交状态
      }
    }
  };
  </script>
  
  
  <style scoped>
  .login-container {
    max-width: 400px;
    margin: auto;
    margin-top: 15%;
    padding: 2rem;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #42b983;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #369970;
  }
  </style>
  