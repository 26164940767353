<template>
  <div class="text-image-management">
    <h1>{{ title }}</h1>
    <button @click="handleAddRow" :disabled="isSaving">新增图文</button>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>图片</th>
            <th>文本介绍</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in combinedTextImageList" :key="item.TextImageID || index">
            <td>{{ index + 1 }}</td>
            <td>
              <img :src="item.OtherImage" alt="Image Preview" v-if="item.OtherImage" class="image-preview" />
              <input type="file" @change="handleFileChange($event, index)" />
            </td>
            <td>
              <input v-model="item.OtherText" placeholder="输入文本介绍" />
            </td>
            <td>
              <button class="save-btn" @click="handleSaveItem(item.TextImageID, item)" :disabled="isSaving">
                {{ isSaving ? '保存中...' : '保存' }}
              </button>
              <button class="delete-btn" @click="handleDeleteItem(item.TextImageID, index)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { defineProps } from 'vue';

const props = defineProps({
  configId: {
    type: Number,
    required: true,
  },
});

const store = useStore();
const textImageList = computed(() => store.getters.textImageList);
const temporaryTextImageList = ref([]); // 临时存储尚未保存的图文条目
const title = ref('图文管理'); // 标题
const isSaving = ref(false); // 用于控制保存状态

// 组合临时和已保存的图文列表
const combinedTextImageList = computed(() => [
  ...textImageList.value,
  ...temporaryTextImageList.value,
]);

const fetchTextImageList = () => {
  store.dispatch('fetchTextImageList', props.configId);
};

const handleAddRow = () => {
  if (!isSaving.value) {
    temporaryTextImageList.value.push({ ConfigID: props.configId, OtherText: '', OtherImage: '' });
  }
};

const handleFileChange = (event, index) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const newImage = e.target.result;
      if (index < textImageList.value.length) {
        store.commit('UPDATE_TEXT_IMAGE_DESC', { id: textImageList.value[index].TextImageID, updatedItem: { ...textImageList.value[index], OtherImage: newImage, File: file } });
      } else {
        temporaryTextImageList.value[index - textImageList.value.length].OtherImage = newImage;
        temporaryTextImageList.value[index - textImageList.value.length].File = file;
      }
    };
    reader.readAsDataURL(file);
  }
};

const handleSaveItem = async (id, item) => {
  isSaving.value = true;
  try {
    if (id) {
      await store.dispatch('updateTextImageDesc', { id, item });
    } else {
      await store.dispatch('addTextImageDesc', item);
      // 保存成功后从临时列表移除
      temporaryTextImageList.value = temporaryTextImageList.value.filter(tempItem => tempItem !== item);
      fetchTextImageList();
    }
  } catch (error) {
    console.error('保存失败', error);
  } finally {
    isSaving.value = false;
  }
};

const handleDeleteItem = (id, index) => {
  if (confirm('确定要删除此项吗？')) {
    if (id) {
      store.dispatch('deleteTextImageDesc', id);
    } else {
      temporaryTextImageList.value.splice(index - textImageList.value.length, 1);
    }
  }
};

onMounted(() => {
  fetchTextImageList();
});
</script>

<style scoped>
.text-image-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.add-button {
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  margin: 20px 0;
}

.image-preview {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
}

button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover {
  background-color: #45a049;
}
</style>
