import { getConfigDetails } from '@/api/api';

const productModule = {
  state: () => ({
    productTitle: '',
    productSubtitle: '',
  }),
  mutations: {
    SET_PRODUCT_DETAILS(state, { configuration, product_model, series_name }) {
      state.productTitle = `${configuration} ${product_model}`;
      state.productSubtitle = series_name;
    },
  },
  actions: {
    async fetchProductDetails({ commit }, configId) {
      try {
        const response = await getConfigDetails(configId);
        const { configuration, product_model, series_name } = response.data;
        commit('SET_PRODUCT_DETAILS', { configuration, product_model, series_name });
      } catch (error) {
        console.error('查询配置详情时发生错误:', error);
      }
    },
  },
  getters: {
    productTitle: (state) => state.productTitle,
    productSubtitle: (state) => state.productSubtitle,
  },
};

export default productModule;
