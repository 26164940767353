import { updateUserContact } from '@/api/api';
import { notifySuccess, notifyError } from '@/utils/notify';

const userContactModule = {
  actions: {
    async updateUserContact(_, userData) {
      try {
        const response = await updateUserContact(userData.UserID, userData);
        console.log('user111:',userData.UserID)
        if (response.status === 200) {
          notifySuccess('更新成功！');
        }
      } catch (error) {
        notifyError('更新失败，请重试。');
        console.error('更新失败:', error);
      }
    }
  }
};

export default userContactModule;
