import axios from 'axios';

const apiClient = axios.create({
  // baseURL: 'https://www.etong-ev.com/api/yitong/', // 替换为你的后端地址
  baseURL: 'https://www.etong-ev.com/api/yitong/',

  headers: {
    'Content-Type': 'application/json'
  }
});



export const adminLogin = (credentials) => apiClient.post('/admin/login', credentials);


export const getCompanyInfo = () => apiClient.get('/company-info');
export const updateCompanyInfo = (id, formData) => apiClient.put(`/company-info/${id}`, formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

//////////////////////


// 获取所有产品型号，不根据系列ID筛选
export const getAllProductModels = () => apiClient.get('/product_models/all');

// 获取所有配置，不根据车型号筛选
export const getAllConfigurations = () => apiClient.get('/configurations');


///////////////////////
export const getSeriesList = () => apiClient.get('/series');//从服务器获取系列列表。
export const addSeries = (series) => apiClient.post('/series', series);//向服务器添加一个新的系列。
export const updateSeries = (id, series) => apiClient.put(`/series/${id}`, series);//更新服务器上现有的系列。
export const deleteSeries = (id) => apiClient.delete(`/series/${id}`);//从服务器删除指定的系列。

export const getProductModels = (seriesId) => apiClient.get(`/product_models?seriesId=${seriesId}`);
export const addProductModel = (model) => apiClient.post('/product_models', model);
export const updateProductModel = (id, model) => apiClient.put(`/product_models/${id}`, model);
export const deleteProductModel = (id) => apiClient.delete(`/product_models/${id}`);

// src/api/api.js

export const getConfigurationByID = (id) => apiClient.get(`/configurations1/${id}`);
export const getConfigurations = (modelId) => apiClient.get(`/configurations/${modelId}`);
export const uploadConfiguration = (config) => apiClient.post('/upload', config);
export const deleteConfiguration = (id) => apiClient.delete(`/configurations/${id}`);
export const saveConfig = (formData, configID) => {
  const url = configID ? `/configurations/${configID}` : '/upload';
  if (configID) {
    return apiClient.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } else {
    return apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
export const saveConfig1 = (formData, configID) => {
  const url = configID ? `/configurations/${configID}` : '/upload1';
  if (configID) {
    return apiClient.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } else {
    return apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};
export const getCategoryList = () => apiClient.get('/categories');
export const addCategory = (category) => apiClient.post('/categories', category);
export const updateCategory = (id, category) => apiClient.put(`/categories/${id}`, category);
export const deleteCategory = (id) => apiClient.delete(`/categories/${id}`);


export const getParameterTitlesByCategory = (categoryID) => apiClient.get(`/parameter_titles/category/${categoryID}`);
export const addParameterTitle = (parameterTitle) => apiClient.post('/parameter_titles', parameterTitle);
export const updateParameterTitle = (id, parameterTitle) => apiClient.put(`/parameter_titles/${id}`, parameterTitle);
export const deleteParameterTitle = (id) => apiClient.delete(`/parameter_titles/${id}`);

// export const getUserList = () => apiClient.get('/users');
export const getUserList = (page, pageSize) => {
  return apiClient.get('/users', {
    params: { page, pageSize }
  });
};
export const addUser = (user) => apiClient.post('/users', user);
export const updateUser = (id, user) => apiClient.put(`/users/${id}`, user);
export const deleteUser = (id) => apiClient.delete(`/users/${id}`);
export const updateUserContact = (userId, userData) => {
  return apiClient.put(`/users/${userId}`, userData);
};


export const getPerformanceList = (config_id) => apiClient.get(`/performance/${config_id}`);
export const deletePerformance = (id) => apiClient.delete(`/performance/${id}`);
export const addPerformance = (formData, performance_id) => {
  const url = performance_id ? `/performance/${performance_id}` : '/performance';
  if (performance_id) {
    return apiClient.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } else {
    return apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

// 获取主页管理列表
export const getHomepageList = () => apiClient.get('/homepage');

// 添加主页信息
export const addHomepage = (formData) => {
  return apiClient.post('/homepage', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// 更新主页信息
export const updateHomepage = (id, formData) => {
  return apiClient.put(`/homepage/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// 删除主页信息
export const deleteHomepage = (id) => apiClient.delete(`/homepage/${id}`);


export default {
  // 获取所有系列配置
  getSeriesConfigurations() {
    return apiClient.get('/series/configurations');
  }
};

export const getConfigurationsBySeriesID = (seriesID) => {
  return apiClient.get(`/series/${seriesID}/configurations`);
};


export const getVehicleParameters = (configId) => apiClient.get(`/vehicle_parameters/${configId}`);
export const createOrUpdateVehicleParameters = (configId, parameters) => 
  apiClient.post(`/vehicle_parameters/${configId}`, parameters);

export const getVehicleConfig = (configID) => apiClient.get(`/main_parameters/${configID}`);
export const getParametersByConfigID = (config_id) => apiClient.get(`/parameters/${config_id}`);

// 获取配置详情
export const getConfigDetails = (configId) => {
  return apiClient.get(`/configuration/${configId}`);
};

// 获取特定配置ID的车型列表
export const fetchModelsByConfigID = (configID) => {
  return apiClient.get(`/configurations/model/${configID}`);
};

export const fetchSeriesWithImages = () => {
  return apiClient.get('/series/with-first-image');
};