<template>
  <div class="main-page" @wheel="handleScroll">

     <!-- 精选标题 -->
    <div class="featured-title">{{ $t('message.featured') }}</div>
    <!-- 编号: 100020000 车1盒子 -->
    <div ref="carContainer" class="car-container">
      <CarBox
        v-for="(car, index) in homecars"
        :key="index"
        :car="car"
        :isFirstImage="index === 0"
        @first-image-loaded="loadCarDisplay"
      />
    </div>

    <CarDisplay v-if="isCarDisplayVisible" :cars="cars" />
    <div style="height: 10vh;"></div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import CarBox from '../components/CarBox.vue';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import CarDisplay from '../components/CarDisplay.vue';

const store = useStore();

// 示例车辆数据
const cars = computed(() => store.getters.cars);

// 车数据
// 从 store 中获取主页信息列表
const homecars = computed(() => store.getters.homepageList);

// 控制 `CarDisplay` 的显示状态
const isCarDisplayVisible = ref(false);

// 在组件挂载时获取主页信息列表
onMounted(() => {
  store.dispatch('fetchHomepageList');
  store.dispatch('loadCars');
});

const loadCarDisplay = () => {
  nextTick(() => {
    isCarDisplayVisible.value = true;
  });
};

</script>

<style scoped>
/* 编号: 100000000 主页面样式 */
.main-page {
  position: relative;
  overflow-y: auto;
  height: 105vh;
  scroll-snap-type: y mandatory; /* 启用滚动捕捉 */
  background-color: #fff;
}
/* 精选标题样式 */
.featured-title {
  margin-top: 13vh;
  font-size: 23px;
  font-weight: bold;
  color: #000000;
  text-align: left;
  padding-left: 4vh;
  margin-bottom: 1vh; /* 标题与车项之间的间距 */
}

.car-container {
  display: flex; /* 使其为水平布局 */
  overflow-x: auto; /* 启用水平滚动 */
  padding: 1vh 2vh 4vh 5vh; /* 分别设置上、右、下、左的内边距 */
  scroll-snap-type: x mandatory; /* 启用滚动捕捉 */
  scroll-padding-left: 30px; /* 保留滚动时的左侧距离 */
    /* 隐藏滚动条 */
    scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 和 Edge */
}

.car-box {
  scroll-snap-align: start; /* 每个盒子对齐开始 */
}

</style>
