import { addHomepage, getHomepageList, updateHomepage, deleteHomepage } from '@/api/api';
import { notifySuccess, notifyError } from '@/utils/notify';

const homepageModule = {
  state: () => ({
    homepageList: [], // 保存主页信息列表
  }),
  mutations: {
    SET_HOMEPAGE_LIST(state, homepageList) { // 设置主页信息列表
      state.homepageList = homepageList;
    },
    UPDATE_HOMEPAGE(state, updatedHomepage) { // 更新主页信息
      const index = state.homepageList.findIndex(hp => hp.HomepageID === updatedHomepage.HomepageID);
      if (index !== -1) {
        state.homepageList[index] = updatedHomepage;
      }
    },
    REMOVE_HOMEPAGE(state, homepageId) { // 删除主页信息
      state.homepageList = state.homepageList.filter(hp => hp.HomepageID !== homepageId);
    },
  },
  actions: {
    async fetchHomepageList({ commit }) { // 获取主页列表
      try {
        const response = await getHomepageList();
        commit('SET_HOMEPAGE_LIST', response.data);
      } catch (error) {
        console.error('获取主页列表失败', error);
        notifyError('获取主页列表失败');
      }
    },
    async addHomepage({ dispatch }, formData) { // 新增主页后重新获取主页列表
      try {
        await addHomepage(formData);
        dispatch('fetchHomepageList'); // 重新获取主页列表
        notifySuccess('主页添加成功');
      } catch (error) {
        console.error('添加主页管理项失败', error);
        notifyError('添加主页失败');
      }
    },
    async updateHomepage({ commit }, { id, formData }) { // 更新主页信息
      try {
        const response = await updateHomepage(id, formData);
        commit('UPDATE_HOMEPAGE', response.data);
        notifySuccess('主页更新成功');
      } catch (error) {
        console.error('更新主页管理项失败', error);
        notifyError('更新主页失败');
      }
    },
    async deleteHomepage({ commit }, id) { // 删除主页信息
      try {
        await deleteHomepage(id);
        commit('REMOVE_HOMEPAGE', id);
        notifySuccess('主页删除成功');
      } catch (error) {
        console.error('删除主页管理项失败', error);
        notifyError('删除主页失败');
      }
    },
  },
  getters: {
    homepageList: state => state.homepageList,
  },
};

export default homepageModule;
