<template>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th v-if="showIndex">序号</th>
            <th v-for="header in headers" :key="header.key">
              {{ header.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, idx) in data" :key="row[idKey]">
            <td v-if="showIndex">{{ idx + 1 }}</td>
            <td v-for="header in headers" :key="header.key">
              <slot :name="header.slotName || 'default'" :row="row" :header="header">
                <component
                  v-if="header.type !== 'actions'"
                  :is="header.component || 'input'"
                  :type="header.type || 'text'"
                  v-model="row[header.key]"  
                  v-bind="getComponentProps(header, row)"
                />
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from 'vue'; // 正确引入 defineProps
  // eslint-disable-next-line no-unused-vars
  const props = defineProps({
    data: Array,
    headers: Array,
    idKey: {
      type: String,
      default: 'id',
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
  });
  
// 生成组件的绑定属性
const getComponentProps = (header, row) => {
  const props = {};
  if (header.type === 'checkbox') {
    props['checked'] = !!row[header.key]; 
    props['onChange'] = (event) => {
      row[header.key] = event.target.checked;
    };
  } else {
    props['value'] = row[header.key] || ''; 
    props['disabled'] = header.disabled || false; // 如果disabled为true，则禁用编辑
    props['onInput'] = (event) => {
      row[header.key] = event.target.value;
    };
  }
  return props;
};
  </script>
  
  <style scoped>
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    display: block;
    margin: 0 auto;
  }
  </style>
  