<!-- src/management/mgmSidebar.vue -->
<template>
  <div class="mgmsidebar">
    <h2 class="sidebar-title">Management</h2>
    <ul>
      <li><router-link to="/admin" exact-active-class="exact-active">Home</router-link></li>
      <li><router-link to="/admin/category-management" exact-active-class="exact-active">参数管理</router-link></li>
      <li><router-link to="/admin/series-management" exact-active-class="exact-active">系列管理</router-link></li>
      <li><router-link to="/admin/vehicle-management" exact-active-class="exact-active">车辆管理</router-link></li>
      <li><router-link to="/home-management">主页管理</router-link></li>
      <li><router-link to="/admin/customer-management" exact-active-class="exact-active">客户资源管理</router-link></li>
      <li><router-link to="/company-info-management" exact-active-class="exact-active">公司简介管理</router-link></li>
    </ul>
  </div>
</template>

<script setup>
</script>

<style scoped>
.mgmsidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2c3e50;
  color: white;
  padding-top: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #ecf0f1;
}

.mgmsidebar ul {
  list-style-type: none;
  padding: 0;
}

.mgmsidebar li {
  margin: 20px 0;
}

.mgmsidebar a {
  color: #ecf0f1;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  transition: background 0.3s, color 0.3s;
}

.mgmsidebar a:hover {
  background-color: #34495e;
}

.mgmsidebar a.router-link-active.exact-active {
  font-weight: bold;
  background-color: #1abc9c;
  color: white;
}
</style>
