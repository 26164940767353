<template>
    <div class="container">
      <button class="close-button" @click="$emit('close')">×</button>
      <h1>车辆参数管理</h1>
      <form @submit.prevent="handleSubmit">
        <div
          class="section"
          v-for="(section, sectionIndex) in groupedParameters"
          :key="sectionIndex"
        >
          <h2>{{ section.category_name }}</h2>
          <div class="form-row">
            <div
              class="form-group"
              v-for="(param, index) in section.parameters"
              :key="index"
            >
              <label :for="param.parameter_id">{{ param.parameter_name }}</label>
              <input
                v-model="param.parameter_value"
                :id="param.parameter_id"
                :name="'parameter-' + param.parameter_id"
                @input="handleInputChange(param.parameter_id, param.parameter_value)"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="submit-button">提交</button>
      </form>
    </div>
  </template>
  
  <script setup>
  import { computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { defineProps } from 'vue';
  
  const props = defineProps({
    configId: {
      type: Number,
      required: true,
    },
  });
  
  const store = useStore();
  
  const vehicleParameters = computed(() => {
    return store.getters.vehicleParameters;
  });
  
  // 根据参数进行分组的函数
  const groupedParameters = computed(() => {
    const categories = {};
  
    vehicleParameters.value.forEach((section) => {
      if (!categories[section.category_name]) {
        categories[section.category_name] = {
          category_name: section.category_name,
          parameters: [],
        };
      }
      section.parameters.forEach((param) => {
        categories[section.category_name].parameters.push(param);
      });
    });
  
    return Object.values(categories);
  });
  
  // 获取车辆参数的函数
  const fetchVehicleParameters = () => {
    console.log('123:',props.configId)
    store.dispatch('fetchVehicleParameters', props.configId);
  };
  
  // 提交表单的处理函数
  const handleSubmit = () => {
    // 将参数转换为后端所需的扁平化数组格式
    const paramsToSubmit = [];
  
    groupedParameters.value.forEach((section) => {
      section.parameters.forEach((param) => {
        paramsToSubmit.push({
          parameter_id: param.parameter_id,
          parameter_value: param.parameter_value,
        });
      });
    });
  
    // 调用Vuex的action并传递参数
    store.dispatch('saveVehicleParameters', { configId: props.configId, paramsToSubmit });
  };
  
  // 处理输入框值变化的函数
  const handleInputChange = (parameterId, value) => {
    store.dispatch('updateParameterValue', { parameterId, parameterValue: value });
  };
  
  // 组件挂载时获取参数
  onMounted(() => {
    fetchVehicleParameters();
  });
  </script>
  
  
  <style scoped>
  /* 样式保持不变 */
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.close-button:hover {
  color: #ff0000;
}
  h1 {
    text-align: center;
    font-size: 2em;
    color: #333;
  }
  
  .section {
    margin-bottom: 2em;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  h2 {
    margin-bottom: 1em;
    font-size: 1.5em;
    color: #555;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5em;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .form-group {
    flex: 1 1 calc(50% - 20px);
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
  }
  
  label {
    font-size: 1em;
    margin-bottom: 0.5em;
    color: #666;
  }
  
  input {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
  }
  
  input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    font-size: 1.2em;
    color: white;
    background: linear-gradient(90deg, #007bff 0%, #0056b3 100%);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .submit-button:hover {
    background: linear-gradient(90deg, #0056b3 0%, #003d7a 100%);
  }
  </style>
  