import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; 
import geoMixin from '../mixins/geoMixin'; 
import MainPage from '../views/MainPage.vue';
import VehicleParts from '../views/VehiclePartsPage.vue';
import InquiryPage from '../views/InquiryPage.vue';
import ViewAllPage from '../views/ViewAllPage.vue';
import CarDetailsPage from '../views/CarDetailsPage.vue';
import VehicleData from '../views/VehicleData.vue';
import AccessoryDetail from '../views/AccessoryDetail.vue';
import ContactPage from '../views/ContactPage.vue';
import ManagementLayout from '../management/ManagementLayout.vue';
import SeriesManagement from '../management/SeriesManagement.vue';
import VehicleManagement from '../management/VehicleManagement.vue';
import CategoryManagement from '../management/CategoryManagement.vue';
import AdminLogin from '../management/AdminLogin.vue';
import CustomerManagement from '../management/CustomerManagement.vue';
import CompanyInfoManagement from '../management/CompanyInfoManagement.vue';
import HomeManagement from '../management/HomeManagement.vue';
import UserLayout from '../views/UserLayout.vue'; 
import VehicleParameterManagement from '../management/VehicleParameterForm.vue'

const routes = [
  {
    path: '/',
    redirect: '/user/main',  // 访问根路径时重定向到 /user/main
  },
  {
    path: '/:type(user|hongya)',  // 动态匹配 user 和 hongya
    component: UserLayout,
    children: [
      {
        path: 'main',
        name: 'MainPage',
        component: MainPage,
      },
      {
        path: 'VehicleParts',
        name: 'VehicleParts',
        component: VehicleParts,
      },
      {
        path: 'vehicle-data/:configID/:ProductImage',
        name: 'VehicleData',
        component: VehicleData,
      },
      {
        path: 'inquiry/:configID',
        name: 'InquiryPage',
        component: InquiryPage,
      },
      {
        path: 'view-all/:seriesID',
        name: 'ViewAllPage',
        component: ViewAllPage,
      },
      {
        path: 'car-details/:configID',
        name: 'CarDetailsPage',
        component: CarDetailsPage,
      },
      {
        path: 'accessory-detail',
        name: 'AccessoryDetail',
        component: AccessoryDetail,
      },
      {
        path: 'contact',  // 修复路径，去掉前面的斜杠
        name: 'ContactPage',
        component: ContactPage,
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
  },
  {
    path: '/admin',
    component: ManagementLayout,
    children: [
      {
        path: '/company-info-management',
        name: 'CompanyInfoManagement',
        component: CompanyInfoManagement, // 注册公司简介管理页面路由
      },
      {
        path: 'series-management',
        name: 'SeriesManagement',
        component: SeriesManagement,
      },
      {
        path: 'vehicle-management',
        name: 'VehicleManagement',
        component: VehicleManagement,
      },
      {
        path: 'category-management',
        name: 'CategoryManagement',
        component: CategoryManagement,
      },
      {
        path: 'customer-management',
        name: 'CustomerManagement',
        component: CustomerManagement,
      },
      {
        path: '/home-management',
        name: 'HomeManagement',
        component: HomeManagement // 主页管理页面的路由配置
      },
      {
        path: 'vehicle-parameters',
        name: 'VehicleParameterManagement',
        component: VehicleParameterManagement
      },
    ],
  },
  // 其他路由
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



// 全局路由守卫
router.beforeEach((to, from, next) => {
  // 检查是否访问的是以 '/user/' 开头的路径
  if (to.path.startsWith('/user/')) {
    // 调用 getIpGeolocation 方法
    const mixin = geoMixin.methods.getIpGeolocation;
    mixin();
  }

  // 检查是否访问的是以 '/admin' 开头的路径
  const isAuthenticated = store.getters.isAuthenticated;
  if (to.path.startsWith('/admin') && !isAuthenticated) {
    if (to.path !== '/admin/login') {
      next({ path: '/admin/login' }); // 未登录时跳转到登录页面
    } else {
      next(); // 如果已经在登录页面，允许继续访问
    }
  } else {
    next(); // 已登录则继续访问或非/admin路径则继续
  }
});


export default router;