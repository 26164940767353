import { fetchModelsByConfigID } from '@/api/api';

const modelsModule = {
    namespaced: true,
    state: () => ({
      models: [],
      selectedModel: null,
    }),
    mutations: {
      SET_MODELS(state, models) {
        state.models = models;
      },
      SET_SELECTED_MODEL(state, model) {
        state.selectedModel = model;
      },
    },
    actions: {
      async fetchModels({ commit }, configID) {
        try {
          const response = await fetchModelsByConfigID(configID);
          commit('SET_MODELS', response.data);
        } catch (error) {
          console.error('获取型号列表失败', error);
        }
      },
      selectModel({ commit, state }, modelId) {
        const selectedModel = state.models.find(model => model.config_id === parseInt(modelId)) || null;
        commit('SET_SELECTED_MODEL', selectedModel);
      },
    },
    getters: {
      models: (state) => state.models,
      selectedModel: (state) => state.selectedModel,
    },
  };
  

export default modelsModule;
