<template>
  <div class="series-box">
    <div class="series-title">{{ parseTitle(props.title, locale) }}</div>

    <div class="view-all-link" @click="goToViewAllPage(props.seriesID, props.title)">{{ $t('message.viewAll') }}</div>
    <div class="horizontal-scroll-box">
      <div
        v-for="car in props.cars"
        :key="car.configID"
        class="car-container"
        @click="goToDetailsPage(car.configID)"  
      >
        <div class="car-image-wrapper">
          <img :src="car.src" :alt="`Car ${car.configID}`" class="car-image" />
        </div>
        <div class="car-model-name">{{ car.name }}</div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { parseTitle } from '@/utils/i18nHelper';

const { locale } = useI18n();  // 获取当前语言环境

const props = defineProps({
  seriesID: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  cars: {
    type: Array,
    required: true
  }
});

const router = useRouter();

const goToViewAllPage = (seriesID, title) => {
  router.push({ 
    name: 'ViewAllPage', 
    params: { seriesID }, 
    query: { title }
  });
};

const goToDetailsPage = (configID) => {
  if (props.title === '配件') {
    router.push({ name: 'AccessoryDetail', params: { configID } });
  } else {
    router.push({ name: 'CarDetailsPage', params: { configID } });
  }
};
</script>

<style scoped>
.series-box {
  position: relative;
  width: 100%;
  padding: 4.55vw;
  box-sizing: border-box;
}

.series-title {
  margin-bottom: 2vw;
  color: rgb(0, 0, 0);
  font-size: 3.72vw;
  text-align: left;
  font-family: PingFangSC-bold;
  font-weight: bold;
  margin-left: 3vw;
}

.view-all-link {
  margin-bottom: 2.33vw;
  color: rgb(0, 0, 0);
  font-size: 3.72vw;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.horizontal-scroll-box {
  width: 100%;
  height: 23.29vh;
  display: flex;
  overflow-x: auto;
  gap: 4.65vw;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.horizontal-scroll-box::-webkit-scrollbar {
  display: none;
}

.car-container {
  position: relative;
  flex: 0 0 auto;
  aspect-ratio: 4 / 3;
  height: 19.42vh;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0.8vw 1.6vw rgba(0, 0, 0, 0.2); /* 添加阴影 */
  overflow: hidden;
  border-radius: 15px;
  margin-left: 3vw;
}

.car-image-wrapper {
  width: 90%; /* 调整图片容器宽度以使图片有内边距 */
  height: 90%; /* 调整图片容器高度以使图片有内边距 */
  margin: auto; /* 将图片容器居中 */
  padding: 1vw; /* 给图片添加内边距 */
  box-sizing: border-box;
}

.car-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 保持图片的比例，确保完整显示 */
  border-radius: 0.5vw; /* 添加轻微的圆角以配合外部容器 */
}

.car-model-name {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3vh;
  line-height: 3vh;
  font-size: 3vw;
  text-align: left;
  color: rgb(0, 0, 0);
  font-family: PingFangSC-bold;
  /* background-color: rgba(255, 255, 255, 0.6); */
  padding-left: 1.16vw;
  box-sizing: border-box;
}
</style>
