import { getVehicleConfig } from '@/api/api';
import {  notifyError } from '@/utils/notify';

const vehicleConfigModule = {
  namespaced: true,
  state: () => ({
    vehicleConfigData: [],
  }),
  mutations: {
    SET_VEHICLE_CONFIG(state, data) {
      state.vehicleConfigData = data;
    },
  },
  actions: {
    async fetchVehicleConfig({ commit }, configID) {
      try {
        const response = await getVehicleConfig(configID);
        commit('SET_VEHICLE_CONFIG', response.data);
      } catch (error) {
        notifyError('获取车辆配置数据失败');
        console.error('获取车辆配置数据失败', error);
      }
    },
  },
  getters: {
    vehicleConfigData: state => state.vehicleConfigData,
  },
};

export default vehicleConfigModule;
