<template>
  <div class="car-box">
    <!-- 车名和图片盒子，设置顶部间隙 -->
    <div class="car-image-container">
      <!-- 车名放在图片左上角，但不覆盖在图片内部 -->
      <div class="car-model">{{ Configuration }}</div>

      <!-- 车底图，裁剪中间的正方形部分，图片占据页面70% -->
      <img :src="detailImage" alt="Car Background" class="car-background" />
    </div>

    <!-- 分割线 -->
    <div class="divider"></div>

    <!-- 车介绍语 -->
    <!-- <div class="car-description">{{ parseTitle(detailDescription, locale) }}</div> -->

    <!-- 动态数据展示 - 使用卡片布局，修改为一行显示三个 -->
    <div class="info-cards">
      <div 
        v-for="(item, index) in sortedVehicleConfigData" 
        :key="index" 
        :class="{'info-card': true, 'full-span': index === 0 && item.parameter_name === '最大限度。速度'}">
        <span class="parameter-name">{{ parseTitle(item.parameter_name, locale) }}</span>
        <span class="parameter-value">{{ item.parameter_value }}</span>
      </div>
    </div>
    <!-- 两个按钮 -->
    <div class="button-group">
      <button class="action-btn" @click="goToVehicleData">{{ $t('message.viewDetails') }}</button>
      <button class="action-btn" @click="navigateToInquiry">{{ $t('message.inquireNow') }}</button>
      <div class="down-arrow">↓</div>
    </div>


  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { defineProps } from 'vue';
import { parseTitle } from '@/utils/i18nHelper'; 
import { useI18n } from 'vue-i18n';  // 使用 vue-i18n 处理多语言

const { locale } = useI18n();  // 获取当前语言环境


// 接收来自父组件的动态数据
const props = defineProps({
  ProductImage: {
    type: String,
    required: true
  },
  detailImage: {
    type: String,
    required: true
  },
  detailDescription: {
    type: String,
    required: true
  },
  Configuration: {
    type: String,
    required: true
  },
  configID: {
    type: Number,
    required: true
  }
});

const router = useRouter();
const store = useStore();

// 确保 Vuex 中的车辆配置数据为数组，即使在加载之前
const vehicleConfigData = computed(() => store.getters['vehicleConfig/vehicleConfigData'] || []);


// 将 "速度" 提到第一个位置
const sortedVehicleConfigData = computed(() => {
  const data = [...vehicleConfigData.value];
  const speedIndex = data.findIndex(item => item.parameter_name === '最大限度。速度');
  
  if (speedIndex !== -1) {
    const speedItem = data.splice(speedIndex, 1)[0]; // 提取速度项
    data.unshift(speedItem); // 将速度项放在数组的第一位
  }
  console.log("Sorted Vehicle Configuration Data:", data);
  return data;
});

function goToVehicleData() {
  router.push({
    name: 'VehicleData',
    params: { configID: props.configID, ProductImage: props.ProductImage }
  });
}

function navigateToInquiry() {
  router.push({ name: 'InquiryPage', params: { configID: props.configID } });
}

// 在组件挂载时调用 API 获取数据
onMounted(() => {
  store.dispatch('vehicleConfig/fetchVehicleConfig', props.configID);
});
</script>

<style scoped>
/* 页面整体布局 */
.car-box {
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh;
  overflow-y: auto;
}

/* 车名和图片整体容器，设置顶部间隙 */
.car-image-container {
  position: relative;
  width: 100%;
  margin-top: 15vh; /* 设置顶部距离 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 车名位置 - 放在图片左上角，字体较小，不覆盖图片，黑色加粗 */
.car-model {
  font-size: 20px;
  font-weight: bold; /* 黑色加粗 */
  color: black;
  margin-left: 2vw;  /* 图片外的左上角 */
  margin-bottom: 1vh;
  margin-top: -3vh; /* 车名距离顶部的间距 */
  align-self: flex-start;  /* 保证车名靠左显示 */
}

/* 图片占据页面的70% */
.car-background {
  width: 55vw;
  max-width: 280px;
  aspect-ratio: 1 / 1; 
  object-fit: cover;
  object-position: center;
  border-radius: 1vh;
  margin-bottom: 2vh;
}

.divider {
  width: 8vw;
  height: 0.2vh;
  background-color: #00bfa5;
  margin: 1.5vh 0;
}

.car-description {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-bottom: 2vh;
}

/* 信息卡片风格，修改为一行显示三个 */
.info-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 一行显示三个 */
  gap: 1.5vh;
  width: 100%;
}

.info-card {
  background-color: white;
  border-radius: 1.2vh;
  padding: 1.5vh;
  box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s ease;
}

/* 如果是 "速度"，就让其动态填充剩余空间 */
.full-span {
  grid-column: span 3; /* 占据三列 */
}

.info-card:hover {
  transform: translateY(-0.5vh);
}

.parameter-name {
  display: block;
  font-size: 13px;
  color: #888;
}

.parameter-value {
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 0.5vh;
}

/* 按钮组 */
.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2.5vh;
}

.action-btn {
  background-color: #90c74b;
  color: white;
  border: none;
  padding: 1.2vh 2.4vh;
  border-radius: 3vh;
  font-size: 1.6vh;
  cursor: pointer;
  width: 45%;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: #008f7a;
}

/* 箭头放在按钮的底部 */
.down-arrow {
  font-size: 2.4vh;
  color: #90c74b;
  margin-top: 2vh;
  animation: bounce 2s infinite;
}

/* 添加动画效果 */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1vh);
  }
  60% {
    transform: translateY(-0.5vh);
  }
}
</style>
