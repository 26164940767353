<template>
    <div class="pagination">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1" class="pagination-btn">
        上一页
      </button>
      <span class="pagination-info">
        第 {{ currentPage }} 页，共 {{ totalPages }} 页
      </span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages" class="pagination-btn">
        下一页
      </button>
  
      <div class="pagination-controls">
        <label for="pageSize" class="page-size-label">每页显示:</label>
        <select id="pageSize" v-model="localPageSize" @change="handlePageSizeChange" class="page-size-select">
          <option value="5">5条</option>
          <option value="10">10条</option>
          <option value="15">15条</option>
        </select>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    currentPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    }
  });
  
  const emits = defineEmits(['updatePage', 'updatePageSize']);
  
  // Create a local state variable for pageSize
  const localPageSize = ref(props.pageSize);
  
  const totalPages = computed(() => Math.ceil(props.totalItems / localPageSize.value));
  
  const changePage = (page) => {
    if (page >= 1 && page <= totalPages.value) {
      emits('updatePage', page);
    }
  };
  
  const handlePageSizeChange = () => {
    emits('updatePageSize', localPageSize.value);
  };
  
  // Watch for changes in the prop and update the local state
  watch(() => props.pageSize, (newSize) => {
    localPageSize.value = newSize;
  });
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pagination-info {
    font-size: 16px;
    color: #333;
  }
  
  .pagination-btn {
    padding: 8px 16px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .pagination-btn:disabled {
    background-color: #e0e0e0;
    color: #999;
    cursor: not-allowed;
  }
  
  .pagination-btn:not(:disabled):hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .page-size-label {
    font-size: 14px;
    color: #333;
  }
  
  .page-size-select {
    padding: 6px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  .page-size-select:hover {
    border-color: #007bff;
  }
  </style>
  