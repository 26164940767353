// src/store/cars.js
import { fetchSeriesWithImages } from '@/api/api';
import { notifyError } from '@/utils/notify'; // 可选：用于通知成功或错误的消息

const carsModule = {
  state: () => ({
    cars: [],
  }),
  mutations: {
    SET_CARS(state, cars) {
      state.cars = cars;
    },
  },
  actions: {
    async loadCars({ commit }) {
      try {
        const response = await fetchSeriesWithImages();
        const cars = response.data.map(item => ({
          id: item.series_id,
          imgSrc: item.product_image,
          name: item.product_model,
        }));
        commit('SET_CARS', cars);
      } catch (error) {
        notifyError('加载车辆数据失败'); // 可选
        console.error('Failed to load cars:', error);
      }
    },
  },
  getters: {
    cars: state => state.cars,
  },
};

export default carsModule;
