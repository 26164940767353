<template>
  <aside class="side-nav" v-show="isVisible">
    <!-- 关闭按钮 -->
    <img src="../assets/关闭.svg" alt="关闭按钮" class="close-button" @click="closeSideNav" />
    <div style="height: 50px;"></div>
    <nav class="nav-menu">
      <ul>
        <li @click="navigateMainPage">{{ $t('message.home') }}</li>
        <li @click="navigateToVehicleParts">{{ $t('message.vehicleParts') }}</li>
        <li @click="navigateToContactPage">{{ $t('message.contact') }}</li>
        <li @click="toggleLanguageMenu">{{ $t('message.switchLanguage') }}</li>
        <li v-if="showLanguageMenu">
          <div class="select-container">
            <select @change="changeLanguage" v-model="selectedLanguage">
              <option value="zh">中文</option>
              <option value="en">English</option>
            </select>
          </div>
        </li>

      </ul>
    </nav>
  </aside>
</template>




<script>
import { useRouter } from 'vue-router';
import { ref , watch} from 'vue'; // 引入 ref 来创建响应式变量
import { useI18n } from 'vue-i18n';

export default {
  name: 'SideNavComponent',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter();
    const showLanguageMenu = ref(false); // 控制语言选择框的显示状态
    const { locale } = useI18n(); 
    const selectedLanguage = ref('en'); 

    watch(selectedLanguage, (newLang) => {
      locale.value = newLang;
    });
    
    const navigateMainPage = () => {
      router.push({ name: 'MainPage' });
    };
    const navigateToVehicleParts = () => {
      router.push({ name: 'VehicleParts' });
    };
    const navigateToContactPage = () => {
      router.push({ name: 'ContactPage' });
    };

    // 显示/隐藏语言选择菜单
    const toggleLanguageMenu = () => {
      showLanguageMenu.value = !showLanguageMenu.value;
    };

    // 切换语言的函数
    const changeLanguage = (event) => {
      const selectedLanguage = event.target.value;
      locale.value = selectedLanguage; // 更新应用的语言
    };

    return {
      navigateMainPage,
      navigateToVehicleParts,
      navigateToContactPage,
      toggleLanguageMenu,
      showLanguageMenu,
      changeLanguage,
      selectedLanguage,
    };
  },
  methods: {
    closeSideNav() {
      this.$emit('close-side-nav');
    }
  }
}

</script>

<style scoped>
.side-nav {
  /* 侧拉导航栏 (编号: 100010100) */
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 5;
  color: #ffffff;
}

.close-button {
  /* 关闭按钮 (编号: 100010101) */
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
}

.nav-menu li {
  /* 导航项 (编号: 100010102 - 100010105) */
  padding: 20px;
  cursor: pointer;
}
.nav-menu select {
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}
.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-container select {
  appearance: none;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease;
}

.select-container select:hover {
  border-color: #888;
}

.select-container::after {
  content: '\25BC'; /* Unicode for dropdown arrow */
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #fff;
  font-size: 12px;
}

</style>
