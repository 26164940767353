// src/store/company.js
import { getCompanyInfo, updateCompanyInfo } from '@/api/api';
import { notifySuccess, notifyError } from '@/utils/notify';

const companyModule = {
  state: () => ({
    companyInfo: {}
  }),
  mutations: {
    SET_COMPANY_INFO(state, companyInfo) {
      state.companyInfo = companyInfo;
    },
    UPDATE_COMPANY_INFO(state, updatedInfo) {
      Object.assign(state.companyInfo, updatedInfo);
    }
  },
  actions: {
    async fetchCompanyInfo({ commit }) {
      try {
        const response = await getCompanyInfo();
        commit('SET_COMPANY_INFO', response.data);
      } catch (error) {
        notifyError('获取公司信息失败');
        console.error('获取公司信息失败', error);
      }
    },
    async updateCompanyInfo({ commit }, { id, formData }) {
      try {
        const response = await updateCompanyInfo(id, formData);
        commit('UPDATE_COMPANY_INFO', response.data);
        notifySuccess('公司信息更新成功');
      } catch (error) {
        notifyError('更新公司信息失败');
        console.error('更新公司信息失败', error);
      }
    }
  },
  getters: {
    companyInfo: state => state.companyInfo
  }
};

export default companyModule;
