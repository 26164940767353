<template>
  <div class="home-management">
    <h1>{{ title }}</h1>
    <!-- 新增按钮 -->
    <button @click="addNewRow">新增主页</button>
    <!-- 表格展示 -->
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>系列</th>
            <th>车型号</th>
            <th>配置号</th>
            <th>主页底图</th>
            <th>简介</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(homepage, index) in homepageList" :key="index">
            <td>
              <select v-model="homepage.selectedSeries" @change="filterProductModels(homepage, index)">
                <option value="" disabled>请选择系列</option>
                <option v-for="series in seriesList" :key="series.SeriesID" :value="series.SeriesID">
                  {{ series.SeriesName }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="homepage.selectedModel" @change="filterConfigurations(homepage, index)" :disabled="!homepage.selectedSeries">
                <option value="" disabled>请选择车型号</option>
                <option v-for="model in filteredProductModels(index)" :key="model.ModelID" :value="model.ModelID">
                  {{ model.ProductModel }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="homepage.selectedConfiguration" :disabled="!homepage.selectedModel">
                <option value="" disabled>请选择配置号</option>
                <option v-for="config in filteredConfigurations(index)" :key="config.ConfigID" :value="config.ConfigID">
                  {{ config.Configuration }}
                </option>
              </select>
            </td>
            <td class="image-upload-cell">
              <div class="image-preview-container">
                <img :src="homepage.HomepageImage" v-if="homepage.HomepageImage" class="image-preview">
              </div>
              <div class="file-upload-container">
                <label class="upload-label">
                  上传图片
                  <input type="file" @change="handleImageUpload($event, index)" class="file-input" />
                </label>
                <small class="upload-hint">建议尺寸：392x800px，支持格式：JPG, PNG</small>
              </div>
            </td>

            <td>
              <textarea v-model="homepage.HomepageDescription" placeholder="输入简介"></textarea>
            </td>
            <td>
              <button @click="saveOrUpdateHomepage(index)" :disabled="isSaving">
                {{ isSaving ? '保存中...' : (homepage.HomepageID ? '更新' : '保存') }}
              </button>
              <button @click="deleteHomepage(homepage.HomepageID)" v-if="homepage.HomepageID" :disabled="isSaving">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import '@/assets/table.css';

const store = useStore();

const title = ref('主页管理');
const homepageList = ref([]);
const seriesList = computed(() => store.state.seriesList);
const productModelList = computed(() => store.getters.productModelList);
const configList = computed(() => store.getters.configurationList);
const isSaving = ref(false); // 保存操作状态

// 过滤车型号和配置号
const filteredProductModels = (index) => {
  const selectedSeries = homepageList.value[index].selectedSeries;
  return productModelList.value.filter(model => model.SeriesID === selectedSeries);
};

const filteredConfigurations = (index) => {
  const selectedModel = homepageList.value[index].selectedModel;
  return configList.value.filter(config => config.ModelID === selectedModel);
};

// 页面加载时获取所有数据
onMounted(async () => {
  try {
    await Promise.all([
      store.dispatch('fetchSeriesList'), // 获取所有系列
      store.dispatch('fetchAllProductModels'), // 获取所有产品型号
      store.dispatch('fetchAllConfigurations') // 获取所有配置
    ]);

    console.log('获取的系列列表:', store.state.seriesList);
    console.log('获取的所有产品型号列表:', store.getters.productModelList);
    console.log('获取的所有配置列表:', store.getters.configurationList);

    // 初始化首页列表
    await store.dispatch('fetchHomepageList');
    const homepageData = store.getters.homepageList;

    homepageData.forEach(homepage => {
      homepageList.value.push({
        ...homepage,
        selectedSeries: homepage.series_id || '',
        selectedModel: homepage.model_id || '',
        selectedConfiguration: homepage.ConfigID || '',
        HomepageImage: homepage.HomepageImage || '',
        HomepageDescription: homepage.HomepageDescription || '',
        imageFile: null, // 初始加载时不需要 imageFile
      });
    });

  } catch (error) {
    console.error('获取初始数据时出错:', error);
  }
});

// 新增一行
const addNewRow = () => {
  homepageList.value.push({
    selectedSeries: '',
    selectedModel: '',
    selectedConfiguration: '',
    HomepageImage: '',
    HomepageDescription: '',
    imageFile: null,
  });
};

// 处理图片上传
const handleImageUpload = (event, index) => {
  const file = event.target.files[0];
  const homepage = homepageList.value[index];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      homepage.HomepageImage = e.target.result;
      homepage.imageFile = file;
    };
    reader.readAsDataURL(file);
  }
};

// 保存/更新主页信息
const saveOrUpdateHomepage = async (index) => {
  const homepage = homepageList.value[index];
  const formData = new FormData();
  
  if (homepage.imageFile) {
    formData.append('homepage_image', homepage.imageFile);
  }
  formData.append('ConfigID', homepage.selectedConfiguration);
  formData.append('HomepageDescription', homepage.HomepageDescription);

  try {
    isSaving.value = true; // 开始保存，禁用按钮
    if (homepage.HomepageID) {
      await store.dispatch('updateHomepage', { id: homepage.HomepageID, formData });
    } else {
      await store.dispatch('addHomepage', formData);
    }
  } catch (error) {
    console.error("Save or Update failed:", error);
  } finally {
    isSaving.value = false; // 保存结束，启用按钮
  }
};

// 删除主页信息
const deleteHomepage = async (homepageId) => {
  try {
    isSaving.value = true; // 开始删除，禁用按钮
    await store.dispatch('deleteHomepage', homepageId);
    homepageList.value = homepageList.value.filter(homepage => homepage.HomepageID !== homepageId);
  } catch (error) {
    console.error("Delete failed:", error);
  } finally {
    isSaving.value = false; // 删除结束，启用按钮
  }
};

// 根据选择的系列过滤车型号
const filterProductModels = (homepage) => {
  homepage.selectedModel = ''; // 清空已选中的车型号
  homepage.selectedConfiguration = ''; // 清空已选中的配置号
};

// 根据选择的车型号过滤配置号
const filterConfigurations = (homepage) => {
  homepage.selectedConfiguration = ''; // 清空已选中的配置号
};

</script>

<style scoped>
.home-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
}

select, textarea, input[type="file"] {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #45a049;
}

button + button {
  margin-left: 8px;
}

.image-upload-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-preview-container {
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 60px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.file-upload-container {
  text-align: center;
}

.file-input {
  display: none;
}

.upload-label {
  padding: 6px 12px;
  background-color: #4CAF50;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-label:hover {
  background-color: #45a049;
}

.upload-hint {
  margin-top: 4px;
  color: #555;
  font-size: 12px;
}
</style>
