import { createI18n } from 'vue-i18n'; // 引入 vue-i18n

// 导入语言包
const messages = {
  en: {
    message: {
      home: 'Home',
      vehicleParts: 'Vehicle Parts',
      contact: 'Contact Us',
      switchLanguage: 'Switch Language',
      toggleNav: 'Nav',
      moreData: 'More Data',
      inquiry: 'Inquire Now',
      featured: 'Featured',
      product: 'Product',
      loading: 'Loading...',
      viewAll: 'View All',
      noParameters: 'No parameter information available. For details, please click contact.',
      qrCodeTip: 'Please screenshot and scan the QR code with WeChat to add a friend.',
      selectModel: 'Please select a model',
      noPrice: 'No price available',
      contactInfo: 'Contact Information',
      country: 'Country/Region',
      city: 'City',
      email: 'Email',
      phone: 'Phone Number',
      message: 'Message',
      submit: 'Get Free Quote',
      viewDetails: 'View Details',
      inquireNow: 'Inquire Now',
      model: 'Model',
      companyIntro: 'Company Introduction',
      messageBoard: 'Message Board',
      countryPlaceholder: 'Your Country/Region',
      cityPlaceholder: 'Your City',
      emailPlaceholder: 'Your Email',
      phonePlaceholder: 'Your Phone Number',
      messagePlaceholder: 'Your Message',
      submit1: 'Submit',
      products: 'Products',
      explore: 'Explore',
      cart: 'Selected Configurations',
    }
  },
  zh: {
    message: {
      home: '首页',
      vehicleParts: '新车选购',
      contact: '联系我们',
      switchLanguage: '切换语言',
      toggleNav: '导航栏',
      moreData: '详细数据',
      inquiry: '立刻询价',
      featured: '精选',
      product: '产品',
      loading: '加载中...',
      viewAll: '查看全部',
      noParameters: '暂无参数信息,了解详细，点击联系',
      qrCodeTip: '请截图打开微信扫码添加好友',
      selectModel: '请选择型号',
      noPrice: '暂无底价',
      contactInfo: '联系信息',
      country: '国家/地区',
      city: '城市',
      email: '邮箱',
      phone: '手机号码',
      message: '留言',
      submit: '免费获取底价',
      viewDetails: '查看详情',
      inquireNow: '立刻询价',
      model: '型号',
      companyIntro: '公司简介',
      messageBoard: '留言板',
      countryPlaceholder: '你的国家/地区',
      cityPlaceholder: '你的城市',
      emailPlaceholder: '你的邮箱',
      phonePlaceholder: '你的手机号',
      messagePlaceholder: '你的留言',
      submit1: '提交',
      products: '产品',
      explore: '公司简介',
      cart: '已选配置',
    }
  }
};

// 创建 i18n 实例
const i18n = createI18n({
  locale: 'en', // 设置默认语言
  fallbackLocale: 'zh', // 回退语言
  messages // 语言包
});

export default i18n;
