// src/store/modules/parameters.js
import { getParametersByConfigID } from '@/api/api';

const parametersModule = {
    namespaced: true,
  state: () => ({
    parameterSections: [],
  }),
  mutations: {
    SET_PARAMETERS(state, sections) {
      state.parameterSections = sections;
    },
  },
  actions: {
    async fetchParameters({ commit }, configID) {
      try {
        const response = await getParametersByConfigID(configID);
        const sections = response.data.map(section => ({
          title: section.category_name,
          parameters: section.parameters.map(param => ({
            title: param.parameter_name,
            data: param.parameter_value,
          })),
        }));
        commit('SET_PARAMETERS', sections);
      } catch (error) {
        console.error('获取参数数据失败', error);
      }
    },
  },
  getters: {
    parameterSections: state => state.parameterSections,
  },
};

export default parametersModule;
