import { getPerformanceList, addPerformance,  deletePerformance } from '@/api/api';
import { notifySuccess, notifyError } from '@/utils/notify';

const state = {
  performanceList: [],
};

const mutations = {
  SET_PERFORMANCE_LIST(state, performanceList) {
    state.performanceList = performanceList;
  },
  ADD_PERFORMANCE(state, performance) {
    state.performanceList.push(performance);
  },
  
  UPDATE_PERFORMANCE(state, { id, updatedPerformance }) {
    const index = state.performanceList.findIndex(performance => performance.PerformanceID === id);
    if (index !== -1) {
      state.performanceList[index] = updatedPerformance;
    }
  },
  DELETE_PERFORMANCE(state, id) {
    state.performanceList = state.performanceList.filter(performance => performance.PerformanceID !== id);
  },
};

const actions = {
  fetchPerformanceList({ commit }, configId) {
    return new Promise((resolve, reject) => {
      getPerformanceList(configId).then(response => {
        commit('SET_PERFORMANCE_LIST', response.data);
        resolve(response);
      }).catch(error => {
        notifyError('获取性能列表失败');
        console.error('获取性能列表失败', error);
        reject(error);
      });
    });
  },
    async addPerformance({ commit }, { formData, performance_id }) {
      try {
        const response = await addPerformance(formData, performance_id);
        commit('ADD_PERFORMANCE', response.data);
        notifySuccess('性能更新成功');
      } catch (error) {
        notifyError('添加性能失败');
        console.error('添加更新失败', error);
      }
    },

    
  deletePerformance({ commit }, id) {
    return new Promise((resolve, reject) => {
      deletePerformance(id).then(() => {
        commit('DELETE_PERFORMANCE', id);
        notifySuccess('性能删除成功');
        resolve();
      }).catch(error => {
        notifyError('删除性能失败');
        console.error('删除性能失败', error);
        reject(error);
      });
    });
  },
};

const getters = {
  performanceList: state => state.performanceList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
