// src/utils/notify.js
import { useToast } from 'vue-toastification';

const toast = useToast();

export const notifySuccess = (message) => {
    toast.success(message);
};

export const notifyError = (message) => {
    toast.error(message);
};
