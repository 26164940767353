// src/store/index.js
import { createStore } from 'vuex';
import { adminLogin } from '@/api/api';
import { getSeriesList, addSeries, updateSeries, deleteSeries } from '@/api/api';
import { getProductModels, addProductModel, updateProductModel, deleteProductModel,uploadConfiguration,getConfigurations, updateConfiguration, deleteConfiguration,getAllProductModels,getAllConfigurations } from '@/api/api';
import { getCategoryList, addCategory, updateCategory, deleteCategory, getParameterTitlesByCategory, addParameterTitle, updateParameterTitle, deleteParameterTitle } from '@/api/api';
import { saveConfig, saveConfig1} from '@/api/api.js';
import { notifySuccess, notifyError } from '@/utils/notify';
import user from './user.js';
import performanceModule from './performance.js';
import textImageModule from './modules/textImageDesc';
import companyModule from './modules/company.js'; 
import homepageModule from './modules/homepage.js'; 
import configurationModule from './modules/configuration'; 
import userContact from './modules/userContact';
import carData from './modules/carData'; 
import series from './modules/series';
import vehicleParametersModule from './modules/vehicleParameters';
import vehicleConfigModule from './modules/vehicleConfig';
import parametersModule from './modules/parameters';
import productModule from './modules/product';
import modelsModule from './modules/models';
import carsModule from './modules/cars';


const store = createStore({
    modules: {
        user,
        performance: performanceModule,
        textImage: textImageModule,
        company: companyModule,
        homepage: homepageModule,
        configuration: configurationModule, 
        userContact,
        carData, 
        series, 
        vehicleParameters: vehicleParametersModule,
        vehicleConfig: vehicleConfigModule,
        parameters: parametersModule,
        product: productModule,
        models: modelsModule,
        cars: carsModule,
    },
    state: {
        admin: null,
        token: localStorage.getItem('user-token') || null, // 从 localStorage 恢复 token
        seriesList: [],
        productModelList: [],
        configurationList: [],
        categoryList: []
    },
    mutations: {
        SET_ADMIN(state, admin) {
            state.admin = admin;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('user-token', token); // 存储 token 到 localStorage
        },
        CLEAR_TOKEN(state) {
            state.token = null;
            localStorage.removeItem('user-token'); // 移除 token
        },
        SET_SERIES_LIST(state, seriesList) {
            state.seriesList = seriesList;
        },
        ADD_SERIES(state, series) {
            state.seriesList.push(series);
        },
        UPDATE_SERIES(state, { id, updatedSeries }) {
            const index = state.seriesList.findIndex(series => series.SeriesID === id);
            if (index !== -1) {
                state.seriesList[index] = updatedSeries;
            }
        },
        DELETE_SERIES(state, id) {
            state.seriesList = state.seriesList.filter(series => series.SeriesID !== id);
        },

        ////////////
        SET_PRODUCT_MODEL_LIST(state, productModelList) {
            state.productModelList = productModelList;
        },
        ADD_PRODUCT_MODEL(state, model) {
            state.productModelList.push(model);
        },
        UPDATE_PRODUCT_MODEL(state, { id, updatedModel }) {
            const index = state.productModelList.findIndex(model => model.ModelID === id);
            if (index !== -1) {
                state.productModelList[index] = updatedModel;
            }
        },
        DELETE_PRODUCT_MODEL(state, id) {
            state.productModelList = state.productModelList.filter(model => model.ModelID !== id);
        },

        ////////////
        SET_CONFIGURATION_LIST(state, configurationList) {
            state.configurationList = configurationList;
          },
          ADD_CONFIGURATION(state, config) {
            state.configurationList.push(config);
          },
          UPDATE_CONFIGURATION(state, { id, updatedConfig }) {
            const index = state.configurationList.findIndex((config) => config.ConfigID === id);
            if (index !== -1) {
              state.configurationList[index] = updatedConfig;
            }
          },
          DELETE_CONFIGURATION(state, id) {
            state.configurationList = state.configurationList.filter((config) => config.ConfigID !== id);
          },
          ////////////
          SET_CATEGORY_LIST(state, categoryList) {
            state.categoryList = categoryList;
        },
        ADD_CATEGORY(state, category) {
            state.categoryList.push(category);
        },
        UPDATE_CATEGORY(state, { id, updatedCategory }) {
            const index = state.categoryList.findIndex(category => category.CategoryID === id);
            if (index !== -1) {
                state.categoryList[index] = updatedCategory;
            }
        },
        DELETE_CATEGORY(state, id) {
            state.categoryList = state.categoryList.filter(category => category.CategoryID !== id);
        },
        ////////////
        SET_PARAMETER_TITLE_LIST(state, parameterTitleList) {
            state.parameterTitleList = parameterTitleList;
        },
        ADD_PARAMETER_TITLE(state, parameterTitle) {
            state.parameterTitleList.push(parameterTitle);
        },
        UPDATE_PARAMETER_TITLE(state, { id, updatedParameterTitle }) {
            console.log('Action parameter22222:', { id, updatedParameterTitle }); // 输出日志检查传递的参数
            const index = state.parameterTitleList.findIndex(parameter => parameter.ParameterID === id);
            if (index !== -1) {
                state.parameterTitleList[index] = updatedParameterTitle;
            }
        },
        DELETE_PARAMETER_TITLE(state, id) {
            state.parameterTitleList = state.parameterTitleList.filter(parameter => parameter.ParameterID !== id);
        }

    },
    actions: {
        //主页管理
        async fetchAllProductModels({ commit }) {
            try {
              const response = await getAllProductModels(); // 新的 API 请求，获取所有产品型号
              commit('SET_PRODUCT_MODEL_LIST', response.data);
            } catch (error) {
              console.error('获取所有产品型号失败:', error);
            }
          },
          async fetchAllConfigurations({ commit }) {
            try {
              const response = await getAllConfigurations(); // 新的 API 请求，获取所有配置
              commit('SET_CONFIGURATION_LIST', response.data);
            } catch (error) {
              console.error('获取所有配置失败:', error);
            }
          },


        //////////////////////////////
        async adminLogin({ commit }, credentials) {
            try {
                const response = await adminLogin(credentials);
                const token = response.data.token; // 假设后端返回的数据包含一个 token
                commit('SET_TOKEN', token);
                notifySuccess('登录成功');
            } catch (error) {
                notifyError('登录失败');
                throw error;
            }
        },
        async fetchSeriesList({ commit }) {
            try {
                const response = await getSeriesList();
                commit('SET_SERIES_LIST', response.data);
            } catch (error) {
                notifyError('获取系列列表失败');
                console.error('获取系列列表失败', error);
            }
        },
        async addSeries({ commit }, series) {
            try {
                const response = await addSeries(series);
                commit('ADD_SERIES', response.data);
                notifySuccess('系列添加成功');
            } catch (error) {
                notifyError('添加系列失败');
                console.error('添加系列失败', error);
            }
        },
        async updateSeries({ commit }, { id, series }) {
            try {
                await updateSeries(id, series);
                commit('UPDATE_SERIES', { id, updatedSeries: series });
                notifySuccess('系列更新成功');
            } catch (error) {
                notifyError('更新系列失败');
                console.error('更新系列失败', error);
            }
        },
        async deleteSeries({ commit }, id) {
            try {
                await deleteSeries(id);
                commit('DELETE_SERIES', id);
                notifySuccess('系列删除成功');
            } catch (error) {
                notifyError('删除系列失败');
                console.error('删除系列失败', error);
            }
        },

        async fetchProductModels({ commit }, seriesId) {
            try {
                const response = await getProductModels(seriesId);
                commit('SET_PRODUCT_MODEL_LIST', response.data);
            } catch (error) {
                notifyError('获取产品型号列表失败');
                console.error('获取产品型号列表失败', error);
            }
        },
        async addProductModel({ commit }, model) {
            try {
                const response = await addProductModel(model);
                commit('ADD_PRODUCT_MODEL', response.data);
                notifySuccess('产品型号添加成功');
            } catch (error) {
                notifyError('添加产品型号失败');
                console.error('添加产品型号失败', error);
            }
        },
        async updateProductModel({ commit }, { id, model }) {
            try {
                await updateProductModel(id, model);
                commit('UPDATE_PRODUCT_MODEL', { id, updatedModel: model });
                notifySuccess('产品型号更新成功');
            } catch (error) {
                notifyError('更新产品型号失败');
                console.error('更新产品型号失败', error);
            }
        },
        async deleteProductModel({ commit }, id) {
            try {
                await deleteProductModel(id);
                commit('DELETE_PRODUCT_MODEL', id);
                notifySuccess('产品型号删除成功');
            } catch (error) {
                notifyError('删除产品型号失败');
                console.error('删除产品型号失败', error);
            }
        },

        async fetchConfigurations({ commit }, modelId) {
            try {
                const response = await getConfigurations(modelId);
                commit('SET_CONFIGURATION_LIST', response.data);
            } catch (error) {
              notifyError('获取配置列表失败');
              console.error('获取配置列表失败', error);
            }
          },
          async addConfiguration({ commit },  config) {
            try {
              const response = await uploadConfiguration(config);
              commit('ADD_CONFIGURATION', response.data);
              notifySuccess('配置添加成功');
            } catch (error) {
              notifyError('添加配置失败');
              console.error('添加配置失败', error);
            }
          },
          async updateConfiguration({ commit }, { id, config }) {
            try {
              const response = await updateConfiguration(id, config);
              commit('UPDATE_CONFIGURATION', { id, updatedConfig: response.data });
              notifySuccess('配置更新成功');
            } catch (error) {
              notifyError('更新配置失败');
              console.error('更新配置失败', error);
            }
          },
          async saveConfiguration({ commit }, { formData, configID }) {
            try {
              const response = await saveConfig(formData, configID);
              commit('UPDATE_CONFIGURATION', { configID, updatedConfig: response.data });
              // 假设你有一个通知系统
              notifySuccess('配置保存成功');
            } catch (error) {
              notifyError('保存配置失败');
              console.error('保存配置失败', error);
            }
          },
          async saveConfiguration1({ commit }, { formData, configID }) {
            try {
              const response = await saveConfig1(formData, configID);
              commit('UPDATE_CONFIGURATION', { configID, updatedConfig: response.data });
              // 假设你有一个通知系统
              notifySuccess('保存成功');
            } catch (error) {
              notifyError('保存配置失败');
              console.error('配置失败', error);
            }
          },
          async deleteConfiguration({ commit }, id) {
            try {
              await deleteConfiguration(id);
              commit('DELETE_CONFIGURATION', id);
              notifySuccess('配置删除成功');
            } catch (error) {
              notifyError('删除配置失败');
              console.error('删除配置失败', error);
            }
          },
          async fetchCategoryList({ commit }) {
            try {
                const response = await getCategoryList();
                commit('SET_CATEGORY_LIST', response.data);
            } catch (error) {
                notifyError('获取参数分类列表失败');
                console.error('获取参数分类列表失败', error);
            }
        },
        async addCategory({ commit }, category) {
            try {
                const response = await addCategory(category);
                commit('ADD_CATEGORY', response.data);
                notifySuccess('参数分类添加成功');
            } catch (error) {
                notifyError('添加参数分类失败');
                console.error('添加参数分类失败', error);
            }
        },
        async updateCategory({ commit }, { id, category }) {
            try {
                await updateCategory(id, category);
                commit('UPDATE_CATEGORY', { id, updatedCategory: category });
                notifySuccess('参数分类更新成功');
            } catch (error) {
                notifyError('更新参数分类失败');
                console.error('更新参数分类失败', error);
            }
        },
        async deleteCategory({ commit }, id) {
            try {
                await deleteCategory(id);
                commit('DELETE_CATEGORY', id);
                notifySuccess('参数分类删除成功');
            } catch (error) {
                notifyError('删除参数分类失败');
                console.error('删除参数分类失败', error);
            }
        },
        async fetchParameterTitlesByCategory({ commit }, categoryID) {
            try {
                const response = await getParameterTitlesByCategory(categoryID);
                commit('SET_PARAMETER_TITLE_LIST', response.data);
            } catch (error) {
                notifyError('获取参数标题列表失败');
                console.error('获取参数标题列表失败', error);
            }
        },
        async addParameterTitle({ commit }, parameterTitle) {
            try {
                const response = await addParameterTitle(parameterTitle);
                commit('ADD_PARAMETER_TITLE', response.data);
                notifySuccess('参数标题添加成功');
            } catch (error) {
                notifyError('添加参数标题失败');
                console.error('添加参数标题失败', error);
            }
        },
        async updateParameterTitle({ commit }, { id, parameterTitle }) {
            try {
                await updateParameterTitle(id, parameterTitle);
                commit('UPDATE_PARAMETER_TITLE', { id, updatedParameterTitle: parameterTitle });
                notifySuccess('参数标题更新成功');
            } catch (error) {
                notifyError('更新参数标题失败');
                console.error('更新参数标题失败', error);
            }
        },
        async deleteParameterTitle({ commit }, id) {
            try {
                await deleteParameterTitle(id);
                commit('DELETE_PARAMETER_TITLE', id);
                notifySuccess('参数标题删除成功');
            } catch (error) {
                notifyError('删除参数标题失败');
                console.error('删除参数标题失败', error);
            }
        }
    },
    getters: {
        admin: state => state.admin,
        isAuthenticated: state => !!state.token, // 检查是否已登录
        seriesList: state => state.seriesList,
        productModelList: state => state.productModelList,
        configurationList: (state) => state.configurationList,
        categoryList: state => state.categoryList,
        parameterTitleList: state => state.parameterTitleList
    },
});

export default store;
