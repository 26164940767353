<template>
  <div class="customer-management">
    <h1>{{ title }}</h1>
    <BaseTable :data="userList" :headers="tableHeaders" id-key="UserID">
      <template #actions="{ row }">
        <button class="save-btn" @click="handleUpdateUser(row.UserID, row)">保存</button>
        <button class="delete-btn" @click="handleDeleteUser(row.UserID)">删除</button>
      </template>
    </BaseTable>

    <BasePagination 
      :currentPage="currentPage" 
      :totalItems="totalUsers" 
      :pageSize="pageSize" 
      @updatePage="changePage" 
      @updatePageSize="updatePageSize" 
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import BaseTable from './BaseComponents/BaseTable.vue';
import BasePagination from './BaseComponents/BasePagination.vue';

const store = useStore();
const title = ref('客户资源管理');
const currentPage = ref(1);
const pageSize = ref(5);
const totalUsers = ref(0);

const tableHeaders = ref([
  { label: 'IP地址', key: 'IPAddress', disabled: true },
  { label: '国家/地区', key: 'Country', disabled: true },
  { label: '城市', key: 'City', disabled: true },
  { label: '访问时间', key: 'AccessTime', component: 'input', type: 'text', disabled: true },
  { label: '留言的产品', key: 'SelectedProduct', component: 'input', disabled: true },
  { label: '邮箱', key: 'Email', component: 'input', disabled: true },
  { label: '留言内容', key: 'MessageContent', component: 'textarea', disabled: true },
  { label: '是否跟进', key: 'FollowUp', component: 'input', type: 'checkbox' },
  { label: '操作', key: 'actions', slotName: 'actions' }
]);

const userList = ref([]);

const fetchUserList = async () => {
  try {
    const response = await store.dispatch('fetchUserList', {
      page: currentPage.value,
      pageSize: pageSize.value
    });
    userList.value = response.users;
    totalUsers.value = response.total;
  } catch (error) {
    console.error('获取用户列表失败', error);
  }
};

const handleUpdateUser = (id, user) => {
  store.dispatch('updateUser', { id, user });
};

const handleDeleteUser = (id) => {
  store.dispatch('deleteUser', id).then(() => {
    userList.value = userList.value.filter(user => user.UserID !== id);
    totalUsers.value -= 1;
  }).catch(error => {
    console.error('删除用户失败', error);
  });
};

const changePage = (page) => {
  currentPage.value = page;
  fetchUserList();
};
const updatePageSize = (size) => {
  pageSize.value = size;
  currentPage.value = 1; // 切换每页显示条数后，通常会回到第一页
  fetchUserList();
};
onMounted(() => {
  fetchUserList();
});
watch(pageSize, fetchUserList); // 监听 pageSize 的变化，自动更新数据
</script>

<style scoped>
.customer-management {
  font-family: 'Arial', sans-serif;
  margin: 20px;
}

h1 {
  text-align: center;
  color: #333;
}
</style>
