<template>
  <div class="mgmheader">
    <h1>Yitong Management</h1>
    <h1 @click="confirmLogout" class="logout-link">退出登录</h1>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import store from '../store'; 
const router = useRouter();

const confirmLogout = () => {
  // 弹出确认对话框
  if (confirm('你确定要退出登录吗？')) {
    logout();
  }
};


const logout = () => {
  // 清除 Vuex 中的用户状态
  store.commit('CLEAR_TOKEN');  // 假设你有一个用于清除token的mutations

  // 清除 localStorage 中的 token（如果有）
  localStorage.removeItem('user-token');

  // 跳转到登录页面
  router.push('/admin/login');
};
</script>

<style scoped>
.mgmheader {
  width: calc(100% - 200px); /* 减去左边的偏移量 */
  height: 60px;
  position: fixed;
  top: 0;
  left: 200px;
  background-color: #4CAF50;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  padding-right: 20px; /* 确保链接不会紧贴右边 */
}

.mgmheader h1 {
  margin: 0; /* 确保标题没有默认外边距 */
}

.logout-link {
  margin-left: auto; /* 自动将元素推到右边 */
  color: white;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.logout-link:hover {
  text-decoration: none;
}
</style>
