import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueLazyload from 'vue-lazyload';
import i18n from './i18n';



const app = createApp(App);

app.use(VueLazyload, {
  loading: '', // 这里可以根据需要动态加载图片
});

// 恢复 token 并初始化 store 的状态
const token = localStorage.getItem('user-token');
if (token) {
  store.commit('SET_TOKEN', token);
}
app.use(router);
app.use(store);
app.use(Toast);

app.use(i18n);
app.mount('#app');
